import SpinnerPng from "../img/loading-spinner.png";

const LoadingSpinner = (props) => {
  return (
    <div
      className={
        props.loading === "loading" ? "spinner-visible" : "spinner-hidden"
      }
    >
      <img src={SpinnerPng} alt="loading spinner" />
    </div>
  );
};

export default LoadingSpinner;
