import React from "react";
import Logo from "../img/logo.svg";

const ForgotPassword = () => {
  return (
    <div className="login-container">
      <img src={Logo} alt="tams header company logo" />

      <div className="recover-password-wrapper">
        <h2>Password Reset</h2>

        <div>
          An email has been sent. Please click the link when you get it.
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
