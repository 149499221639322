import { useState, useEffect, createContext } from "react";
import { Route } from "react-router-dom";
import Cookies from "js-cookie";

import useDeepEffect from "./components/hooks/useDeepEffect";
import Header from "./header";
import Home from "./home";
import Organization from "./pages/organizationGet";
import User from "./pages/userGet";
import OrganizationListPage from "./pages/organizationListPage";
import OrganizationForm from "./pages/organizationForm";
import UserListPage from "./pages/userListPage";
import UserForm from "./pages/userForm";
import OverviewPage from "./pages/OverviewPage";
import SideNavbar from "./components/sideNavbar";
import ProfileEdit from "./pages/profileEditPage";
import UniversalSearch from "./components/universalSearch";
import logout from "./util/logout";
import CodModal from "./pages/CodModal";
import InventoryManagement from "./pages/InventoryManagement";
import AccessManagementPage from "./pages/accessManagementPage";
import PurchaseOrderManagement from "./pages/PurchaseOrderManagement";
import { awaitAPICall } from "./util/apiWrapper";
import SecurityWrapper from "./util/securityWrapper";
import DocumentsPage from "./pages/DocumentsPage";

export const UserContext = createContext();

const DefaultContainer = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [userData, setUserData] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [myCompanyName, setMyCompanyName] = useState("");
  const [cvCode, setCvCode] = useState("");
  const [loggedInUser, setLoggedInUser] = useState({});

  const [renderedColumns, setRenderedColumns] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [justReconData, setJustReconData] = useState([]);
  const [justInventoryData, setJustInventoryData] = useState([]);

  const [inventoryPage, setInventoryPage] = useState(1);
  const [reconPage, setReconPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);

  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [justPurchaseOrderData, setJustPurchaseOrderData] = useState([]);
  const [reportPage, setReportPage] = useState(1);

  useEffect(() => {
    let auth_token_from_cookie = Cookies.get("auth_token");
    let expiration = Cookies.get("auth_expires");
    let is_expired = Date.parse(expiration) < Date.now();
    if (!auth_token_from_cookie || is_expired) {
      logout(props);
    }
  });

  useEffect(() => {
    awaitAPICall(
      "/user/get",
      "GET",
      null,
      null,
      (data) => {
        awaitAPICall(
          "/user/get/me",
          "GET",
          null,
          null,
          (data) => {
            if (data) {
              setOrgId(data.org_id);
              setCvCode(data.organization.cv_code);
              setMyCompanyName(data.organization.name);
              setLoggedInUser(data);
            }
          },
          (err) => console.warn("user/get/me Error: ", err),
          true
        );
        setUserData(data);
      },
      (err) => console.log("User Fetch Error: ", err),
      true
    );
  }, []);

  useDeepEffect(() => {
    if (loggedInUser?.organization?.cv_code) {
      awaitAPICall(
        "/organization/add",
        "POST",
        {
          user_org: loggedInUser.organization.cv_code,
        },
        (res) => res,
        null,
        (err) => console.warn("Error Updating Organization Option: ", err),
        true
      );
    }
  }, [loggedInUser]);

  return (
    <div className="container">
      <Route
        path="/"
        render={(iprops) => (
          <Header
            {...iprops}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            authToken={props.authToken}
            setAuthToken={props.setAuthToken}
          />
        )}
      />
      <Route path="/" component={SideNavbar} />
      <div className="body-wrapper">
        <UserContext.Provider value={{ userData }}>
          <Route path="/home" component={Home}></Route>

          <Route exact path="/user/:user_id" component={User} />
          <Route path="/user/edit/:user_id" component={UserForm} />

          <Route path="/users" component={UserListPage} />

          <Route exact path="/user-add" component={UserForm} />
          <Route path="/user-add/:org_id/:org_name" component={UserForm} />

          <Route path="/organizations" component={OrganizationListPage} />
          <Route path="/organization/:org_id" component={Organization} />
          <Route exact path="/organization-form" component={OrganizationForm} />
          <Route
            path="/organization-form/:org_id"
            component={OrganizationForm}
          />

          <Route
            path="/inventory-management"
            render={(props) => {
              return (
                <InventoryManagement
                  {...props}
                  cvCode={cvCode}
                  inventoryPage={inventoryPage}
                  setInventoryPage={setInventoryPage}
                  reconPage={reconPage}
                  setReconPage={setReconPage}
                  searchPage={searchPage}
                  setSearchPage={setSearchPage}
                  setUserData={setUserData}
                  renderedColumns={renderedColumns}
                  setRenderedColumns={setRenderedColumns}
                  inventoryData={inventoryData}
                  setInventoryData={setInventoryData}
                  justInventoryData={justInventoryData}
                  setJustInventoryData={setJustInventoryData}
                  justReconData={justReconData}
                  setJustReconData={setJustReconData}
                />
              );
            }}
          />
          <Route path="/cod-modal" component={CodModal} />

          <Route
            path="/overview"
            render={(props) => {
              return <OverviewPage {...props} loggedInUser={loggedInUser} />;
            }}
          />
          <Route path="/profile/edit/:user_id" component={ProfileEdit} />
        </UserContext.Provider>

        <SecurityWrapper restrict_roles="user">
          <Route
            path="/access-management"
            render={(props) => {
              return (
                <AccessManagementPage
                  {...props}
                  cvCode={cvCode}
                  orgId={orgId}
                  userData={userData}
                  setUserData={setUserData}
                  myCompanyName={myCompanyName}
                  loggedInUser={loggedInUser}
                />
              );
            }}
          />
        </SecurityWrapper>

        <SecurityWrapper restrict_roles="user">
          <SecurityWrapper restrict_roles="admin">
            <Route
              path="/purchase-order-management"
              render={(props) => {
                return (
                  <PurchaseOrderManagement
                    {...props}
                    cvCode={cvCode}
                    reportPage={reportPage}
                    setReportPage={setReportPage}
                    searchPage={searchPage}
                    setSearchPage={setSearchPage}
                    setUserData={setUserData}
                    renderedColumns={renderedColumns}
                    setRenderedColumns={setRenderedColumns}
                    purchaseOrderData={purchaseOrderData}
                    setPurchaseOrderData={setPurchaseOrderData}
                    justPurchaseOrderData={justPurchaseOrderData}
                    setJustPurchaseOrderData={setJustPurchaseOrderData}
                  />
                );
              }}
            />
          </SecurityWrapper>
        </SecurityWrapper>

        <Route
          path="/documents-page"
          render={(props) => {
            return (
              <DocumentsPage
                {...props}
                cvCode={cvCode}
                loggedInUser={loggedInUser}
              />
            );
          }}
        />

        <Route
          path="/universal-search"
          render={(props) => {
            return (
              <UniversalSearch
                {...props}
                searchTerm={searchTerm}
                authToken={props.authToken}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default DefaultContainer;
