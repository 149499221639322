import { useState, useEffect } from "react";
import { toCamelCase } from "../../util/stringUtils";

const SearchFilterForm = (props) => {
  const [displayAll, setDisplayAll] = useState(false);
  const [queryInput, setQueryInput] = useState("");
  const [showMsg, setShowMsg] = useState(false);

  const {
    setOpenModal,
    setFilters,
    filters,
    setChosenSelectors,
    setSelectorStorage,
    setSearchExact,
  } = props;

  const handleFilter = (e, filter) => {
    if (!e.target.checked) {
      setFilters((f) => f.filter((fil) => fil !== filter));
    } else {
      setShowMsg(false);
      setFilters((f) => {
        if (filter === "Asset ID") {
          return [...f, "User Defined 1"];
        } else if (filter === "Model") {
          return [...f, "Description"];
        } else {
          return [...f, filter];
        }
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      filters?.length > 0 &&
      (props.renderedReport === "inventory") & (filters?.length <= 12)
    ) {
      props.setSearching("searching");
      props.setSearchQuery(queryInput);
      await props.handleDataPageChange("s1");
      setOpenModal(false);
    } else if (
      filters?.length > 0 &&
      (props.renderedReport === "recon") & (filters?.length <= 20)
    ) {
      props.setSearching("searching");
      props.setSearchQuery(queryInput);
      await props.handleDataPageChange("s1");
      setOpenModal(false);
    } else if (!displayAll) {
      setShowMsg(true);
    } else if (displayAll) {
      props.setSearching("searching");
      props.setSearchQuery(queryInput);
      await props.handleDataPageChange("s1");
      setOpenModal(false);
    }
  };

  const renderInputs = () => {
    const inventoryReportInputs = [
      "Date Received",
      "PO ID",
      "PO Line",
      "Serial Number",
      "MFGR",
      "Model",
      "Asset ID",
      "Item Number",
      "Reference Number",
    ];
    const reconReportInputs = [
      "Serial Number",
      "Item Number",
      "Date Received",
      "Invoiced Date",
      "Customer Tag",
      "Comments",
      "Category",
      "Description",
      "Unit Price",
      "Unit Cost",
      "Cost Build",
      "PO ID",
      "PO Line",
    ];

    const leftInputs =
      props.columns[0].name === "Date Received"
        ? inventoryReportInputs
        : reconReportInputs;

    return leftInputs.map((column) => {
      return (
        <div key={column} className={`${column}`}>
          <input type="checkbox" onChange={(e) => handleFilter(e, column)} />
          <label htmlFor={`column`}>{column}</label>
        </div>
      );
    });
  };

  useEffect(() => {
    if (filters.length > 0) {
      const chosenFilters = filters.map((filter) => toCamelCase(filter));
      setChosenSelectors(chosenFilters);
      setSelectorStorage(chosenFilters);
      setShowMsg(false);
    } else if (displayAll === true) {
      setChosenSelectors(props.selectors);
      setShowMsg(false);
    }
    // eslint-disable-next-line
  }, [filters, displayAll, props.selectors]);

  useEffect(() => {
    setSearchExact(false);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="search-filter-modal">
      <div className="header-section">
        <div className="header">
          <h1>Search Options</h1>
          <h5>
            {showMsg !== false
              ? "Please select an option"
              : "Only the selected fields will be searched"}
          </h5>
          <i
            className="fas fa-times fa-2x"
            onClick={() => props.setOpenModal(false)}
          ></i>
        </div>
      </div>
      <div className="header-checkbox-container">
        <div className="search-all">
          <div className="search-all-checkbox-wrapper">
            <label htmlFor="search-all-label">Search All</label>
            <input
              type="checkbox"
              onChange={() => setDisplayAll((dA) => !dA)}
            />
          </div>
          <div className="search-exact-checkbox-wrapper">
            <label htmlFor="search-exact-label">Exact</label>
            <input
              type="checkbox"
              onChange={() => setSearchExact((sE) => !sE)}
            />
          </div>
          <div className="search-field-wrapper">
            <input
              className="search-field"
              placeholder="Search..."
              onChange={(e) => setQueryInput(e.target.value)}
            />
            <button onClick={handleSubmit}>Search</button>
          </div>
        </div>
      </div>

      <div className="filter-options-container">
        <div className="left-options">{renderInputs("left")}</div>
      </div>
    </div>
  );
};

export default SearchFilterForm;
