import { useState } from "react";

import asyncAPICall from "../../util/apiWrapper";
import UploadDocuments from "./UploadDocuments";

const ClientFileUpload = ({
  setShowModal,
  handleUploadFile,
  companyFileNames,
  fetchDocuments,
  setLoadingForAddDocs,
}) => {
  const [companyName, setCompanyName] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [companyResults, setCompanyResults] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);

  const handleSelectClient = (e) => {
    setCompanyName((cN) => [
      ...cN,
      ...companyResults.filter((cR) => cR.cv_id === e.target.value),
    ]);
    const client_id = e.target.value;
    setCompanyResults((c) => c.filter((c) => c.cv_id !== client_id));
  };

  const handleRemoveClient = (client) => {
    setCompanyResults((c) => [...c, client]);
    setCompanyName((c) => c.filter((c) => c !== client));
  };

  const listClients = () => {
    return (
      <li>
        {companyName.map((c) => {
          return (
            <div key={c.cv_id} className="client-list">
              <div>
                {c?.name?.length > 24 ? c?.name.slice(0, 24) + "..." : c?.name}
              </div>
              <button onClick={() => handleRemoveClient(c)}>
                <i className="fas fa-minus-square fa-lg" />
              </button>
            </div>
          );
        })}
      </li>
    );
  };

  const handleCompanySearch = (e) => {
    e.preventDefault();

    asyncAPICall(
      `/company/search/${searchInput}+active`,
      "GET",
      null,
      null,
      (data) => {
        const dataArray = data;

        handleSetResults(dataArray);
      },
      (err) => console.warn("Company Search Error: ", err),
      true
    );
  };

  const handleSetResults = (dataArray) => {
    const options = dataArray.map((result) => {
      return (
        <option key={result.cv_id} value={result.cv_id}>
          {result.name}
        </option>
      );
    });
    setCompanyOptions(options);
    setCompanyResults(dataArray);
  };

  return (
    <div className="client-upload-container">
      <div className="header-wrapper">
        <div className="upload-header">Upload Documents</div>
        <div className="upload-subheader">
          Supported File Types: xlsx, xlsx, csv, pdf, jpeg, jpg, png, gif, webp,
          svg, tiff, tif, avif
        </div>

        <div className="exit-btn-wrapper">
          <button onClick={() => setShowModal(false)}>
            <i className="fas fa-times fa-lg" />
          </button>
        </div>
      </div>

      <div className="client-content-wrapper">
        <div className="client-company-col">
          <div className="search-label">Search for Clients</div>

          <div className="company-search-container">
            <>
              <div className="company-search-wrapper">
                <input
                  name="company"
                  type="text"
                  placeholder="Enter at least 3 characters"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                {searchInput?.length > 2 ? (
                  <button
                    className="company-search-button"
                    onClick={(e) => handleCompanySearch(e)}
                  >
                    Search
                  </button>
                ) : (
                  <button className="company-search-button" disabled>
                    Search
                  </button>
                )}
              </div>
              <select
                defaultValue={"default-option"}
                name="company"
                onChange={(e) => handleSelectClient(e)}
              >
                <option value="default-option">
                  {"Search Results"} {"("}
                  {companyResults?.length}
                  {")"}
                </option>
                {companyOptions}
              </select>
            </>
          </div>

          <ul>{listClients()}</ul>
        </div>

        <div className="vertical-rule"></div>

        <UploadDocuments
          handleUploadFile={handleUploadFile}
          companyFileNames={companyFileNames}
          setShowModal={setShowModal}
          fetchDocuments={fetchDocuments}
          organizations={companyName}
          type="client"
          setLoadingForAddDocs={setLoadingForAddDocs}
        />
      </div>
    </div>
  );
};

export default ClientFileUpload;
