import UploadDocuments from "./UploadDocuments";

const CompanyUpload = (props) => {
  const { setShowModal, handleUploadFile, companyFileNames, fetchDocuments } =
    props;

  return (
    <div className="company-upload-container">
      <div className="header-wrapper">
        <div className="upload-header">Upload Documents</div>

        <div className="exit-btn-wrapper">
          <button onClick={() => setShowModal(false)}>
            <i className="fas fa-times fa-lg" />
          </button>
        </div>
      </div>

      <div className="company-content-wrapper">
        <UploadDocuments
          handleUploadFile={handleUploadFile}
          companyFileNames={companyFileNames}
          setShowModal={setShowModal}
          fetchDocuments={fetchDocuments}
          organizations={[]}
          type="company"
        />
      </div>
    </div>
  );
};

export default CompanyUpload;
