import Modal from "react-modal";

import CodPdfModalForm from "../forms/CodPdfModalForm";
Modal.setAppElement("#root");

const CodPdfModal = (props) => {
  return (
    <Modal
      isOpen={props.showModal}
      onRequestClose={() => {
        if (props.modalLocation === "po-mgmt") {
          props.setPdfCodData();
        }
        props.setShowModal(false);
      }}
      style={{
        overlay: {
          backdropFilter: "blur(2px)",
          backgroundColor: "rgb(49, 53, 62, 0.5)",
          positon: "absolute",
          top: "-3%",
          zIndex: "15",
        },
        content: {
          positon: "absolute",
          width: "fit-content",
          height: "fit-content",
          top: "51.5%",
          left: "50%",
          zIndex: "15",
          transform: "translate(-50%, -50%)",
          padding: "0",
          border: "none",
          borderRadius: "0px",
          backgroundColor: "transparent",
          filter: "brightness(90%)",
          clipPath:
            "polygon(100% 50%, 92% 65%, 92% 100%, 8% 100%, 8% 65%, 0% 50%, 8% 35%, 8% 0%, 92% 0%, 92% 35%)",
        },
      }}
    >
      <CodPdfModalForm
        modalLocation={props.modalLocation}
        codMessage={props.codMessage}
        pdfCodData={props.pdfCodData}
        codItemsReceived={props.codItemsReceived}
        publicId={props.publicId}
      />
    </Modal>
  );
};

export default CodPdfModal;
