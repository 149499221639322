import Logo from "../img/logo.svg";

const tempPassword = "dhalkfheluhalkjhdkjlfhiue";
const username = "Tim";

const TempPassword = (props) => {
  const redirectTo = (path) => {
    props.history.push(path);
  };

  return (
    <div className="login-container">
      <img src={Logo} alt="tams header company logo" />
      <div className="temp-password-wrapper">
        <div className="recovery-paper">
          <h2 className="header-text">Password Reset</h2>
          <div className="temporary-header-wrapper">
            Your temporary password for {username} is:
          </div>

          <h2>{tempPassword}</h2>

          <div className="instruction-wrapper">
            Please push the button, after which you will be asked to create a
            new password
          </div>

          <button
            className="confirm-button"
            onClick={() => redirectTo(`/login`)}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default TempPassword;
