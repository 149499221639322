import { useState } from "react";

import DestructionCertModal from "../components/modals/DestructionCertModal";

const CodModal = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <button onClick={() => setShowModal((sM) => !sM)}>Open Modal</button>
      <DestructionCertModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default CodModal;
