import Modal from "react-modal";
import PoSearchForm from "../forms/PoSearchForm";

Modal.setAppElement("#root");

const PoSearchModal = (props) => {
  return (
    <Modal
      isOpen={props.openModal ? true : false}
      onRequestClose={() => props.setOpenModal("")}
      style={{
        overlay: {
          backdropFilter: "blur(2px)",
          zIndex: "2",
        },

        content: {
          border: "1px solid black",
          position: "absolute",
          width: "500px",
          height: "fit-content",
          top: "17%",
          left: "50%",
          transform: "translateX(-50%)",
          padding: "0px",
          borderRadius: "10px",
        },
      }}
    >
      <PoSearchForm
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
        setFilterColumns={props.setFilterColumns}
        setRenderedColumns={props.setRenderedColumns}
        renderedColumns={props.renderedColumns}
        columns={props.columns}
        purchaseOrderData={props.purchaseOrderData}
        selectors={props.selectors}
        setChosenSelectors={props.setChosenSelectors}
        chosenSelectors={props.chosenSelectors}
        selectorStorage={props.selectorStorage}
        setSelectorStorage={props.setSelectorStorage}
        searchQuery={props.searchQuery}
        setSearchQuery={props.setSearchQuery}
        setFilters={props.setFilters}
        filters={props.filters}
        searching={props.searching}
        setSearching={props.setSearching}
        setRowResults={props.setRowResults}
        handleDataPageChange={props.handleDataPageChange}
        renderedReport={props.renderedReport}
        searchExact={props.searchExact}
        setSearchExact={props.setSearchExact}
      />
    </Modal>
  );
};

export default PoSearchModal;
