export default function CsvLoadingBar(props) {
  const { width } = props;

  const styles = {
    maxWidth: width,

    transition: "5s all linear",
    visibility: `${props.csvLoading ? "visible" : "hidden"}`,
  };

  return (
    <div
      className={
        props.csvLoading ? "csv-loading-bar-visible" : "csv-loading-bar-hidden"
      }
    >
      <div style={styles}></div>
    </div>
  );
}
