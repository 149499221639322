import { useEffect } from "react";
import Modal from "react-modal";

import { DisplayDocuments } from "../forms/DestructionCert";
Modal.setAppElement("#root");

const DestructionCertModal = (props) => {
  const {
    setCompany,
    company,
    setWeight,
    weight,
    setEquipmentDesc,
    equipmentDesc,
    setDateReceived,
    dateReceived,
    setIssueDate,
    issueDate,
    setCodId,
    codId,
    showModal,
    setShowModal,
    setGenerateCod,
  } = props;

  useEffect(() => {
    if (!showModal) {
      setCompany("Company");
      setWeight("Enter Weight in Pounds  (Numbers Only)");
      setEquipmentDesc("Equipment Description");
      setDateReceived("Date Received");
      setIssueDate("Issue Date");
      setCodId("");
      setGenerateCod(false);
    }
    // eslint-disable-next-line
  }, [showModal]);
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      style={{
        overlay: {
          backdropFilter: "blur(2px)",
          backgroundColor: "rgb(49, 53, 62, 0.5)",
        },
        content: {
          positon: "absolute",
          width: "max(calc(26.247vw * 1.2), 500px)",
          height: "max(calc(34.121vw * 1.2), 650px)",
          top: "54%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "0",
          border: "none",
          borderRadius: "0px",
          backgroundColor: "transparent",
          filter: "brightness(95%)",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        },
      }}
    >
      <DisplayDocuments
        company={company}
        weight={weight}
        equipmentDesc={equipmentDesc}
        dateReceived={dateReceived}
        issueDate={issueDate}
        codId={codId}
        setCompany={setCompany}
        setWeight={setWeight}
        setEquipmentDesc={setEquipmentDesc}
        setDateReceived={setDateReceived}
        setIssueDate={setIssueDate}
        setCodId={setCodId}
        setGenerateCod={setGenerateCod}
        setShowModal={setShowModal}
      />
    </Modal>
  );
};

export default DestructionCertModal;
