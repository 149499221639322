import Modal from "react-modal";

import ClientFileUpload from "../upload-files/ClientFileUpload";
import CompanyUpload from "../upload-files/CompanyUpload";

Modal.setAppElement("#root");

const ClientFilesModal = (props) => {
  const {
    comp,
    showModal,
    setShowModal,
    handleUploadFile,
    fetchDocuments,
    organizations,
    setLoadingForAddDocs,
  } = props;

  const renderUploadComponent = () => {
    switch (comp) {
      case "client":
        return (
          <ClientFileUpload
            setShowModal={setShowModal}
            handleUploadFile={handleUploadFile}
            fetchDocuments={fetchDocuments}
            organizations={organizations}
            setLoadingForAddDocs={setLoadingForAddDocs}
          />
        );
      case "company":
        return (
          <CompanyUpload
            setShowModal={setShowModal}
            handleUploadFile={handleUploadFile}
            fetchDocuments={fetchDocuments}
          />
        );
      default:
        return;
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      style={{
        overlay: {
          backdropFilter: "blur(2px)",
          backgroundColor: "rgb(49, 53, 62, 0.5)",
        },
        content: {
          borderRadius: "20px",
          width: "800px",
          height: "500px",
          position: "absolute",
          top: "26vh",
          left: "50%",
          transform: "translateX(-50%)",
          padding: "0px",
          margin: "0px",
          boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
          overflow: "none",
          border: "none",
          backgroundColor: "transparent",
        },
      }}
    >
      {renderUploadComponent()}
    </Modal>
  );
};

export default ClientFilesModal;
