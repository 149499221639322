import { useState, useEffect } from "react";
import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

function CodPdfModalForm(props) {
  const [pageNum, setPageNum] = useState(1);
  const [pdfLength, setPdfLength] = useState(1);

  const { pdfCodData, modalLocation, codItemsReceived, publicId } = props;

  const cld = new Cloudinary({
    cloud: {
      cloudName: "duwet11zt",
    },
  });

  function handlePageChange(value) {
    const addMinusPage = value ? 1 : -1;

    setPageNum((pN) => pN + addMinusPage);
  }

  function isNumeric(str) {
    if (typeof str != "string") {
      return false;
    }
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  const renderPage = () => {
    let myImage;
    let pdfUrl;
    let mode;

    if (isNumeric(pdfCodData.cod_id)) {
      mode = "automatic";
    } else {
      mode = "manual";
    }

    if (mode === "automatic") {
      myImage = cld.image(
        `CODs/DEV_PNGs_1_3/${pdfCodData.cod_id}/${pdfCodData.cod_id}-${pageNum}.png`
      );
    } else if (mode === "manual") {
      myImage = cld.image(
        `CODs/DEV_PNGs_1_3/${pdfCodData.cod_id}/${pdfCodData.cod_id}.png`
      );
    }

    if (modalLocation === "documents") {
      pdfUrl = pdfCodData.public_id;
    } else if (modalLocation === "po-mgmt") {
      pdfUrl = publicId;
    }

    myImage.format("png");

    return (
      <div className="cod-pdf-modal-form-wrapper">
        <AdvancedImage cldImg={myImage} />
        <div className="pdf-download-link">
          <a
            href={`https://res.cloudinary.com/duwet11zt/image/upload/${pdfUrl}.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            Download PDF
          </a>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const orderLength =
      modalLocation === "documents"
        ? Number(pdfCodData?.items_received)
        : modalLocation === "po-mgmt"
        ? Number(codItemsReceived)
        : 1;
    let totalPages = 1;
    if (orderLength % 30 === 0) {
      totalPages = Math.floor(orderLength / 30);
    } else {
      totalPages = Math.floor(orderLength / 30) + 1;
    }
    setPdfLength(totalPages);
  }, [modalLocation, pdfCodData?.items_received, codItemsReceived]);

  return (
    <div className="cod-pdf-modal-form-container">
      {renderPage()}
      {pageNum > 1 && (
        <i
          className="fas fa-caret-left fa-8x left-icon"
          onClick={() => handlePageChange(false)}
        ></i>
      )}

      {pageNum < pdfLength + 1 && (
        <i
          className="fas fa-caret-right fa-8x right-icon"
          onClick={() => handlePageChange(true)}
        ></i>
      )}
    </div>
  );
}

export default CodPdfModalForm;
