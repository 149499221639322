import { useState } from "react";

import {
  errorToastNotification,
  successfulToast,
} from "../../util/toastNotifications";
import asyncAPICall from "../../util/apiWrapper";

const UploadDocuments = ({
  setShowModal,
  fetchDocuments,
  type,
  organizations,
  setLoadingForAddDocs,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const convertFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const addSelectedFile = async (e) => {
    const fileUploaded = e.target.files[0];
    try {
      await convertFileToBase64(fileUploaded).then((file) => {
        setSelectedFiles((i) => [
          ...i,
          { name: fileUploaded.name, file: file },
        ]);
      });
    } catch {
      errorToastNotification("Error uploading document");
    }
  };

  const handleSubmit = () => {
    const org_ids = organizations.map((org) => {
      return org.org_id;
    });
    let isCompDoc = type === "company" ? true : false;
    if (selectedFiles && selectedFiles.length > 0) {
      setLoadingForAddDocs(true);
      asyncAPICall(
        "/document/add",
        "POST",
        {
          is_company_document: isCompDoc,
          file_data: selectedFiles,
          organization_ids: org_ids,
        },
        null,
        () => {
          fetchDocuments();
          setLoadingForAddDocs(false);
          return successfulToast("File(s) Uploaded Successfully!");
        },
        (err) => {
          setLoadingForAddDocs(false);
          errorToastNotification(
            "something went wrong while uploading your files."
          );
          console.log(err);
        }
      );
    }
    setShowModal(false);
  };

  const handleRemoveFile = (file) => {
    const newSelectedFiles = selectedFiles.filter((f) => f.name !== file);
    setSelectedFiles(newSelectedFiles);
  };

  const listFiles = () => {
    return selectedFiles.map((file, idx) => {
      return (
        <li key={idx} value={file.name} className="file-list">
          <div className="file-name">{file.name}</div>
          <button onClick={() => handleRemoveFile(file.name)}>
            <i className="fas fa-minus-square fa-lg" />
          </button>
        </li>
      );
    });
  };

  return (
    <div className="upload-send-col">
      <div className="documents-add-wrapper">
        <div className="documents-heading">Documents</div>

        <label className="upload-files" htmlFor="attachment-input">
          <i className="fas fa-plus fa-lg" /> Add
          <input
            id="attachment-input"
            type="file"
            accept=".xlsx,.xls,.csv,.jpeg,.tiff,.webp,.avif,.png,.jpg,.tif,.gif,.svg,.pdf"
            onChange={(e) => addSelectedFile(e)}
            onClick={(e) => (e.target.value = null)}
          ></input>
        </label>
      </div>

      <ul>{listFiles()}</ul>

      <button className="send-btn" onClick={handleSubmit}>
        Upload
      </button>
    </div>
  );
};

export default UploadDocuments;
