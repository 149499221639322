import Clipboard from "../img/clipboard.svg";
import ImgIcon from "../img/img-icon.svg";

const PoReportInputs = [
  "Amount",
  "Approved By",
  "Breakdown",
  "Condition Code",
  "Condition ID",
  "Contact: Contact",
  "Contact: Contact ID",
  "Contact Fax",
  "Delivery Date",
  "Down Payment",
  "Equipment Cost",
  "EX Rate",
  "Foreign Amount",
  "Freight",
  "Internal Comment",
  "Is Paid",
  "Late Charge",
  "Located-At Contact",
  "Located-At Contact Id",
  "Located-At Fax",
  "Located-At Phone",
  "Misc Cost",
  "Next Ap Ext",
  "Next Line",
  "PQ ID",
  "PT ID",
  "Purchase Date",
  "Rec Added",
  "Receive Status",
  "Rec Type",
  "Reference Number",
  "Rep",
  "Service Type",
  "Shipper Name",
  "Shipping Comment",
  "Ship-To Contact",
  "Ship-To Contact ID",
  "Ship-To Fax",
  "Ship-To Phone",
  "Ship Via",
  "Ship Via ID",
  "SO Number",
  "Status",
  "ST ID",
  "Terms ID",
  "Total Only",
  "Totals Only",
  "Tracking Number",
  "Trans Address ID",
  "User Defined 1",
  "User Defined 2",
  "We Arrange Transp",
  "We Pay Transp",
  "WO ID",
  "Base Currency",
  "Foreign Equipment Cost",
  "Foreign Freight",
  "Foreign Misc Cost",
  "Is Freight Taxable",
  "Is Misc Taxable",
  "Is Taxable",
  "State Tax",
  "State Tax Pct",
  "Tax 1",
  "Tax 1 Auth",
  "Tax 2",
  "Tax 2 Auth",
  "Tax 3",
  "Tax 3 Auth",
  "Tax 4",
  "Tax 4 Auth",
  "Taxable Sales",
  "Tax State",
  "Total Tax",
  "Foreign State Tax",
  "Foreign Tax 1",
  "Foreign Tax 2",
  "Foreign Tax 3",
  "Foreign Tax 4",
  "Foreign Taxable Sales",
  "Credit Card Info ID",
  "Credit Card Applied Amount",
  "Tax 1 Auth ID",
  "Tax 2 Auth ID",
  "Tax 3 Auth ID",
  "Tax 4 Auth ID",
  "Freight Used",
  "Misc Charge Used",
  "Amount Paid",
  "Amount Paid By",
  "Equipment Purchase",
  "Consignment",
  "Is Trade",
  "Completed",
  "ITAD Template ID",
  "Erasure Method ID",
  "ITAD PO Type",
  "Override Complete",
  "Bill Distribution ID",
];

const inventoryColumns = [
  {
    name: "Date Received",
    selector: (row) => row.dateReceived,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "PO ID",
    selector: (row) => row.poId,
    width: "6.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "PO Line",
    selector: (row) => row.poLine,
    width: "6%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Warehouse",
    selector: (row) => row.warehouse,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Serial Number",
    selector: (row) => row.serialNumber,
    width: "15.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "MFGR",
    selector: (row) => row.mfgr,
    width: "9.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Model",
    selector: (row) => row.model,
    width: "14.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Asset ID",
    selector: (row) => row.assetId,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Item Number",
    selector: (row) => row.itemNumber,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Reference Number",
    selector: (row) => row.referenceNumber,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
];

const reconColumns = [
  {
    name: "Warehouse",
    selector: (row) => row.warehouse,
    width: "11.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Serial Number",
    selector: (row) => row.serialNumber,
    width: "14.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Item Number",
    selector: (row) => row.itemNumber,
    width: "8.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Date Received",
    selector: (row) => row.dateReceived,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Invoiced Date",
    selector: (row) => row.invoicedDate,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Customer Tag",
    selector: (row) => row.customerTag,
    width: "8.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Comments",
    selector: (row) => row.comments,
    width: "11%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Category",
    selector: (row) => row.category,
    width: "8.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Description",
    selector: (row) => row.description,
    width: "13.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Unit Price",
    selector: (row) => row.unitPrice,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Unit Cost",
    selector: (row) => row.unitCost,
    width: "10%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Cost Build",
    selector: (row) => row.costBuild,
    width: "11%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "PO ID",
    selector: (row) => row.poId,
    width: "10%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "PO Line",
    selector: (row) => row.poLine,
    width: "10%",
    style: {
      marginRight: "0.75rem",
    },
  },
];

const destructionCertificateData = [
  {
    purchaseDate: <b data-tag="allowRowEvents">2022-10-12T00:00:00</b>,
    ptId: <b data-tag="allowRowEvents">31911</b>,
    itemsReceived: <b data-tag="allowRowEvents">76</b>,
  },
  {
    purchaseDate: <b data-tag="allowRowEvents">2022-10-14T00:00:00</b>,
    ptId: <b data-tag="allowRowEvents">31928</b>,
    itemsReceived: <b data-tag="allowRowEvents">30</b>,
  },
  {
    purchaseDate: <b data-tag="allowRowEvents">2022-10-17T00:00:00</b>,
    ptId: <b data-tag="allowRowEvents">31940</b>,
    itemsReceived: <b data-tag="allowRowEvents">30</b>,
  },
  {
    purchaseDate: <b data-tag="allowRowEvents">2022-10-17T00:00:00</b>,
    ptId: <b data-tag="allowRowEvents">31942</b>,
    itemsReceived: <b data-tag="allowRowEvents">30</b>,
  },
  {
    purchaseDate: <b data-tag="allowRowEvents">2022-10-17T00:00:00</b>,
    ptId: <b data-tag="allowRowEvents">31943</b>,
    itemsReceived: <b data-tag="allowRowEvents">30</b>,
  },
  {
    purchaseDate: <b data-tag="allowRowEvents">2022-10-17T00:00:00</b>,
    ptId: <b data-tag="allowRowEvents">31946</b>,
    itemsReceived: <b data-tag="allowRowEvents">30</b>,
  },
  {
    purchaseDate: <b data-tag="allowRowEvents">2022-10-17T00:00:00</b>,
    ptId: <b data-tag="allowRowEvents">31947</b>,
    itemsReceived: <b data-tag="allowRowEvents">30</b>,
  },
  {
    purchaseDate: <b data-tag="allowRowEvents">2022-10-17T00:00:00</b>,
    ptId: <b data-tag="allowRowEvents">31949</b>,
    itemsReceived: <b data-tag="allowRowEvents">30</b>,
  },
  {
    purchaseDate: <b data-tag="allowRowEvents">2022-10-18T00:00:00</b>,
    ptId: <b data-tag="allowRowEvents">31949</b>,
    itemsReceived: <b data-tag="allowRowEvents">30</b>,
  },
];

const documentsColumns = [
  {
    selector: (row) => row.docIcon,
    minWidth: "15%",
    style: {
      justifyContent: "flex-start",
      paddingLeft: "1.5rem",
    },
  },
  {
    selector: (row) => row.docTitle,
    minWidth: "62%",
    allowOverflow: true,
    style: {
      justifyContent: "flex-start",
      paddingLeft: "1.5rem",
      overflowX: "auto",
    },
  },
  {
    selector: (row) => row.createdDate,
    minWidth: "22%",
    style: {
      justifyContent: "flex-start",
      paddingLeft: "1vw",
    },
  },
];

const documentsData = [
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={Clipboard}
        alt="clipboard icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
  {
    docIcon: (
      <img
        src={ImgIcon}
        alt="jpeg/png file-type-indication icon"
        data-tag="allowRowEvents"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    ),
    docTitle: <b>Title of Document</b>,
    createdDate: "Aug 15, 2022",
  },
];

const poColumns = [
  {
    name: "Amount",
    selector: (row) => row.amount,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "Approved By",
    selector: (row) => row.approvedBy,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Breakdown",
    selector: (row) => row.breakdown,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Condition Code",
    selector: (row) => row.conditionCode,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Condition ID",
    selector: (row) => row.conditionId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Contact: Contact",
    selector: (row) => row.contactContact,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Contact: Contact ID",
    selector: (row) => row.contactContactId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Contact Fax",
    selector: (row) => row.contactFax,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Contact Phone",
    selector: (row) => row.contactPhone,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "Contract By Us",
    selector: (row) => row.contractByUs,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Contract Comment",
    selector: (row) => row.contractComment,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Contract Sent By",
    selector: (row) => row.contractSentBy,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Conversion Rate",
    selector: (row) => row.conversionRate,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Currency Code",
    selector: (row) => row.currencyCode,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "CV Code",
    selector: (row) => row.cvCode,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "CV Freight ID",
    selector: (row) => row.cvFreightId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Delivery Date",
    selector: (row) => row.deliveryDate,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "Down Payment",
    selector: (row) => row.downPayment,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Equipment Cost",
    selector: (row) => row.equipmentCost,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "EX Rate",
    selector: (row) => row.exRate,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Foreign Amount",
    selector: (row) => row.foreignAmount,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Freight",
    selector: (row) => row.freight,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Internal Comment",
    selector: (row) => row.internalComment,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Is Paid",
    selector: (row) => row.isPaid,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Late Charge",
    selector: (row) => row.lateCharge,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "Located-At Contact",
    selector: (row) => row.locatedAtContact,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Located-At Contact Id",
    selector: (row) => row.locatedAtContactId,
    width: "16.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Located-At Fax",
    selector: (row) => row.locatedAtFax,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Located-At Phone",
    selector: (row) => row.locatedAtPhone,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Misc Cost",
    selector: (row) => row.miscCost,
    width: "9.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Next Ap Ext",
    selector: (row) => row.nextApExt,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Next Line",
    selector: (row) => row.nextLine,
    width: "9.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "PQ ID",
    selector: (row) => row.pqId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "PT ID",
    selector: (row) => row.ptId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Purchase Date",
    selector: (row) => row.purchaseDate,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Rec Added",
    selector: (row) => row.recAdded,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Receive Status",
    selector: (row) => row.receiveStatus,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Rec Type",
    selector: (row) => row.recType,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Reference Number",
    selector: (row) => row.referenceNumber,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Rep",
    selector: (row) => row.rep,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Service Type",
    selector: (row) => row.serviceType,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "Shipper Name",
    selector: (row) => row.shipperName,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Shipping Comment",
    selector: (row) => row.shippingComment,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Ship-To Contact",
    selector: (row) => row.shipToContact,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Ship-To Contact ID",
    selector: (row) => row.shipToContactId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Ship-To Fax",
    selector: (row) => row.shipToFax,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Ship-To Phone",
    selector: (row) => row.shipToPhone,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Ship Via",
    selector: (row) => row.shipVia,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Ship Via ID",
    selector: (row) => row.shipViaId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "SO Number",
    selector: (row) => row.soNumber,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Status",
    selector: (row) => row.status,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "ST ID",
    selector: (row) => row.stId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Terms ID",
    selector: (row) => row.termsId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Total Only",
    selector: (row) => row.totalOnly,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Totals Only",
    selector: (row) => row.totalsOnly,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tracking Number",
    selector: (row) => row.trackingNumber,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Trans Address ID",
    selector: (row) => row.transAddressId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "User Defined 1",
    selector: (row) => row.userDefined1,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "User Defined 2",
    selector: (row) => row.userDefined2,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "We Arrange Transp",
    selector: (row) => row.weArrangeTransp,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "We Pay Transp",
    selector: (row) => row.wePayTransp,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "WO ID",
    selector: (row) => row.woId,
    width: "9.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Base Currency",
    selector: (row) => row.baseCurrency,
    width: "9.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Foreign Equipment Cost",
    selector: (row) => row.foreignEquipmentCost,
    width: "16.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Foreign Freight",
    selector: (row) => row.foreignFreight,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "Foreign Misc Cost",
    selector: (row) => row.foreignMiscCost,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Is Freight Taxable",
    selector: (row) => row.isFreightTaxable,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Is Misc Taxable",
    selector: (row) => row.isMiscTaxable,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Is Taxable",
    selector: (row) => row.isTaxable,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "State Tax",
    selector: (row) => row.stateTax,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "State Tax Pct",
    selector: (row) => row.stateTaxPct,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tax 1",
    selector: (row) => row.tax1,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tax 1 Auth",
    selector: (row) => row.tax1Auth,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "Tax 2",
    selector: (row) => row.tax2,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tax 2 Auth",
    selector: (row) => row.tax2Auth,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tax 3",
    selector: (row) => row.tax3,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tax 3 Auth",
    selector: (row) => row.tax3Auth,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tax 4",
    selector: (row) => row.tax4,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tax 4 Auth",
    selector: (row) => row.tax4Auth,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Taxable Sales",
    selector: (row) => row.taxableSales,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tax State",
    selector: (row) => row.taxState,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "Total Tax",
    selector: (row) => row.totalTax,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Foreign State Tax",
    selector: (row) => row.foreignStateTax,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Foreign Tax 1",
    selector: (row) => row.foreignTax1,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Foreign Tax 2",
    selector: (row) => row.foreignTax2,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Foreign Tax 3",
    selector: (row) => row.foreignTax3,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Foreign Tax 4",
    selector: (row) => row.foreignTax4,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Foreign Taxable Sales",
    selector: (row) => row.foreignTaxableSales,
    width: "14.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Credit Card Info ID",
    selector: (row) => row.creditCardInfoId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "Credit Card Applied Amount",
    selector: (row) => row.creditCardAppliedAmount,
    width: "20.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tax 1 Auth ID",
    selector: (row) => row.tax1AuthId,
    width: "12%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tax 2 Auth ID",
    selector: (row) => row.tax2AuthId,
    width: "12%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tax 3 Auth ID",
    selector: (row) => row.tax3AuthId,
    width: "12%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Tax 4 Auth ID",
    selector: (row) => row.tax4AuthId,
    width: "12%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Freight Used",
    selector: (row) => row.freightUsed,
    width: "12%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "Misc Charge Used",
    selector: (row) => row.miscChargeUsed,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Amount Paid",
    selector: (row) => row.amountPaid,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Amount Paid By",
    selector: (row) => row.amountPaidBy,
    width: "10.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Equipment Purchase",
    selector: (row) => row.equipmentPurchase,
    width: "14.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Consignment",
    selector: (row) => row.consignment,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Is Trade",
    selector: (row) => row.isTrade,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Completed",
    selector: (row) => row.completed,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "ITAD Template ID",
    selector: (row) => row.itadTemplateId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
      marginLeft: "-1rem",
    },
  },
  {
    name: "Erasure Method ID",
    selector: (row) => row.erasureMethodId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "ITAD PO Type",
    selector: (row) => row.itadPoType,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Override Complete",
    selector: (row) => row.overrideComplete,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Bill Distribution ID",
    selector: (row) => row.billDistributionId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
];

const poData = [
  {
    id: 1,
    amount: "The first row",
    approvedBy: "Really long line of mock data.",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "31868",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Received",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 2,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 3,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Another really long line of mock data.",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 4,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 5,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 6,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 7,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 8,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 9,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 10,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 11,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 12,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 13,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 14,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 15,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 16,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 17,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 18,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 19,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 20,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 21,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 22,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 23,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 24,
    amount: "Mock Data",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 25,
    amount: "6th to last row",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 26,
    amount: "5th to last row",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 27,
    amount: "4th to last row",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 28,
    amount: "3rd to last row",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 29,
    amount: "2nd to last row",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
  {
    id: 30,
    amount: "the last row",
    approvedBy: "Mock Data",
    breakdown: "Mock Data",
    conditionCode: "Mock Data",
    conditionId: "Mock Data",
    contactContact: "Mock Data",
    contactContactId: "Mock Data",
    contactFax: "Mock Data",
    contactPhone: "Mock Data",
    contractByUs: "Mock Data",
    contractComment: "Mock Data",
    contractSentBy: "Mock Data",
    conversionRate: "Mock Data",
    currencyCode: "Mock Data",
    cvCode: "Mock Data",
    cvFreightId: "Mock Data",
    deliveryDate: "Mock Data",
    downPayment: "Mock Data",
    equipmentCost: "Mock Data",
    exRate: "Mock Data",
    foreignAmount: "Mock Data",
    freight: "Mock Data",
    internalComment: "Mock Data",
    isPaid: "Mock Data",
    lateCharge: "Mock Data",
    locatedAtContact: "Mock Data",
    locatedAtContactId: "Mock Data",
    locatedAtFax: "Mock Data",
    locatedAtPhone: "Mock Data",
    miscCost: "Mock Data",
    nextApExt: "Mock Data",
    nextLine: "Mock Data",
    pqId: "Mock Data",
    ptId: "Mock Data",
    purchaseDate: "Mock Data",
    recAdded: "Mock Data",
    receiveStatus: "Mock Data",
    recType: "Mock Data",
    referenceNumber: "Mock Data",
    rep: "Mock Data",
    serviceType: "Mock Data",
    shipperName: "Mock Data",
    shippingComment: "Mock Data",
    shipToContact: "Mock Data",
    shipToContactId: "Mock Data",
    shipToFax: "Mock Data",
    shipToPhone: "Mock Data",
    shipVia: "Mock Data",
    shipViaId: "Mock Data",
    soNumber: "Mock Data",
    status: "Mock Data",
    stId: "Mock Data",
    termsId: "Mock Data",
    totalOnly: "Mock Data",
    totalsOnly: "Mock Data",
    trackingNumber: "Mock Data",
    transAddressId: "Mock Data",
    userDefined1: "Mock Data",
    userDefined2: "Mock Data",
    weArrangeTransp: "Mock Data",
    wePayTransp: "Mock Data",
    woId: "Mock Data",
    baseCurrency: "Mock Data",
    foreignEquipmentCost: "Mock Data",
    foreignFreight: "Mock Data",
    foreignMiscCost: "Mock Data",
    isFreightTaxable: "Mock Data",
    isMiscTaxable: "Mock Data",
    isTaxable: "Mock Data",
    stateTax: "Mock Data",
    stateTaxPct: "Mock Data",
    tax1: "Mock Data",
    tax1Auth: "Mock Data",
    tax2: "Mock Data",
    tax2Auth: "Mock Data",
    tax3: "Mock Data",
    tax3Auth: "Mock Data",
    tax4: "Mock Data",
    tax4Auth: "Mock Data",
    taxableSales: "Mock Data",
    taxState: "Mock Data",
    totalTax: "Mock Data",
    foreignStateTax: "Mock Data",
    foreignTax1: "Mock Data",
    foreignTax2: "Mock Data",
    foreignTax3: "Mock Data",
    foreignTax4: "Mock Data",
    foreignTaxableSales: "Mock Data",
    creditCardInfoId: "Mock Data",
    creditCardAppliedAmount: "Mock Data",
    countryCode: "Mock Data",
    contactEmail: "Mock Data",
    shipToEmail: "Mock Data",
    locatedAtEmail: "Mock Data",
    userDefined3: "Mock Data",
    userDefined4: "Mock Data",
    userDefined5: "Mock Data",
    lastUpdatedBy: "Mock Data",
    lastUpdated: "Mock Data",
    supplierMargin: "Mock Data",
    tax1AuthId: "Mock Data",
    tax2AuthId: "Mock Data",
    tax3AuthId: "Mock Data",
    tax4AuthId: "Mock Data",
    freightUsed: "Mock Data",
    miscChargeUsed: "Mock Data",
    amountPaid: "Mock Data",
    amountPaidBy: "Mock Data",
    equipmentPurchase: "Mock Data",
    consignment: "Mock Data",
    isTrade: "Mock Data",
    completed: "Mock Data",
    itadTemplateId: "Mock Data",
    erasureMethodId: "Mock Data",
    itadPoType: "Mock Data",
    serviceFeeTemplateId: "Mock Data",
    overrideComplete: "Mock Data",
    billDistributionId: "Mock Data",
  },
];

export {
  poColumns,
  poData,
  PoReportInputs,
  inventoryColumns,
  reconColumns,
  destructionCertificateData,
  documentsColumns,
  documentsData,
};
