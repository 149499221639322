import { NavLink } from "react-router-dom";

import SecurityWrapper from "../util/securityWrapper";
import poSvg from "../img/money-check-dollar-solid.svg";
import documentsPageSvg from "../img/documentsPageSvg.svg";

const SideNavbar = () => {
  return (
    <div className="side-navbar-container">
      <div className="inside-container">
        <NavLink to="/overview" className="side-nav-links">
          <i className="fas fa-2x fa-street-view" />
          <div className="label-header overview-header">Overview</div>
        </NavLink>

        <NavLink to="/inventory-management" className="side-nav-links">
          <i className="fas fa-2x fa-calendar-alt" />
          <div className="label-header inventory-header">Inventory</div>
          <div className="label-subheader inventory-subheader">Management</div>
        </NavLink>

        <SecurityWrapper restrict_roles="user">
          <SecurityWrapper restrict_roles="admin">
            <NavLink to="/purchase-order-management" className="side-nav-links">
              <img src={poSvg} alt="purchase order" className="po-svg" />
              <div className="label-header purchase-orders-header">POs</div>
            </NavLink>
          </SecurityWrapper>
        </SecurityWrapper>

        <SecurityWrapper restrict_roles="super-admin">
          <NavLink to="/documents-page" className="side-nav-links">
            <img
              src={documentsPageSvg}
              alt="documents page"
              className="docs-svg"
            />
            <div className="label-header documents-header">Documents</div>
          </NavLink>
        </SecurityWrapper>

        <SecurityWrapper restrict_roles="user">
          <NavLink to="/access-management" className="side-nav-links">
            <i className="fas fa-2x fa-user-cog" />
            <div className="label-header access-mgmt-header">Access</div>
            <div className="label-subheader access-mgmt-subheader">
              Management
            </div>
          </NavLink>
        </SecurityWrapper>
      </div>
    </div>
  );
};

export default SideNavbar;
