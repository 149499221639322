import React, { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./styles/App.scss";
import LoginContainer from "./loginContainer";
import DefaultContainer from "./defaultContainer";

function App() {
  const [authToken, setAuthToken] = useState(null);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route
            path="/login"
            render={(props) => {
              return (
                <LoginContainer
                  {...props}
                  authToken={authToken}
                  setAuthToken={setAuthToken}
                />
              );
            }}
          />

          <Redirect exact from="/" to="/login" />
          <Route
            render={(props) => (
              <DefaultContainer
                {...props}
                authToken={authToken}
                setAuthToken={setAuthToken}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
