import { PoReportInputs } from "../../util/reportColumns";

const PoFilterForm = (props) => {
  const {
    filterModalDisplayAll,
    setFilterModalDisplayAll,
    filterModalFilters,
    setFilterModalFilters,
  } = props;

  const handleFilter = (e, filter) => {
    if (!e.target.checked) {
      setFilterModalFilters((f) => f.filter((fil) => fil !== filter));
    } else {
      setFilterModalFilters((f) => [...f, filter]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (filterModalDisplayAll) {
      props.setRenderedColumns(props.columns);
      props.setFiltering(false);
      props.setFilterModalDisplayAll(false);
      props.setFilterModalFilters([]);
    } else {
      props.setFiltering(true);
      props.setRenderedColumns((c) =>
        c.filter((col) => filterModalFilters.includes(col.name))
      );
    }
    props.setOpenModal("");
  };

  const renderInputs = () => {
    return PoReportInputs.map((column) => {
      return (
        <div key={column} className={`${column}`}>
          <input type="checkbox" onChange={(e) => handleFilter(e, column)} />
          <label htmlFor={`column`}>{column}</label>
        </div>
      );
    });
  };

  return (
    <div className="po-filter-modal">
      <div className="header-section">
        <div className="header">
          <h1>Filter Options</h1>
          <h5>Only the selected fields will be displayed</h5>
          <i
            className="fas fa-times fa-2x"
            onClick={() => props.setOpenModal("")}
          />
        </div>
      </div>
      <div className="header-checkbox-container">
        <div className="display-all">
          <input
            type="checkbox"
            className=""
            onChange={() => setFilterModalDisplayAll((dA) => !dA)}
          />
          <label htmlFor="display-all">Display All</label>
          <button onClick={handleSubmit}>Filter</button>
        </div>
      </div>

      <div className="filter-options-container">
        <div className="left-options">{renderInputs("left")}</div>
      </div>
    </div>
  );
};

export default PoFilterForm;
