import { useState, useEffect, useContext, useRef, useCallback } from "react";
import Cookies from "js-cookie";

import asyncAPICall from "../util/apiWrapper";
import FilesModal from "../components/modals/FilesModal";
import { UserContext } from "../defaultContainer";
import {
  errorToastNotification,
  successfulToast,
} from "../util/toastNotifications";
import useDeepEffect from "../components/hooks/useDeepEffect";
import LoadingSpinner from "../components/LoadingSpinner";

const OverviewPage = (props) => {
  const { userData } = useContext(UserContext);
  const [clientModal, setClientModal] = useState(false);
  const [companyFiles, setCompanyFiles] = useState([]);
  const [clientFiles, setClientFiles] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [loggedInUserOrganization, setLoggedInUserOrganization] = useState({});
  const [isSaveEdit, setIsSaveEdit] = useState(false);
  const [overviewInfo, setOverviewInfo] = useState({});
  const [newUsersData, setNewUsersData] = useState([]);
  const [newUsersCount, setNewUsersCount] = useState(0);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [recentDocuments, setRecentDocuments] = useState([]);
  const [loadingForAddDocs, setLoadingForAddDocs] = useState(false);
  const [fileObjects, setfileObjects] = useState([]);
  const [overViewValues, setOverViewValues] = useState({
    top_left_title: "",
    top_left_description: "",
    bottom_left_title: "",
    bottom_left_description: "",
    bottom_right_title: "",
    bottom_right_description: "",
  });
  const [parsedHtmlLeft, setParsedHtmlLeft] = useState("");
  const [parsedHtmlRight, setParsedHtmlRight] = useState("");

  const modalRef = useRef(false);

  const userRole = Cookies.get("user_role");

  const { loggedInUser } = props;

  const handleUploadFile = (e, type) => {
    const fileUploaded = e.target.files[0];
    try {
      convertFileToBase64(fileUploaded).then((file) => {
        if (file && type === "company") setCompanyFiles((i) => [file, ...i]);
        else setClientFiles((i) => [file, ...i]);
      });
    } catch {
      errorToastNotification("Error uploading document");
    }
  };

  const handleDeleteFile = (file, type) => {
    asyncAPICall(
      `/document/delete/${file.document_id}`,
      "DELETE",
      null,
      null,
      (data) => {
        const filteredFiles = clientFiles.filter(
          (f) => f.document_id !== file.document_id
        );
        successfulToast(
          "File(s) Deleted Successfully. Refresh if changes aren't shown."
        );
        setRecentDocuments([]);
        return setClientFiles(filteredFiles);
      },
      (err) => {
        errorToastNotification(
          "something went wrong with deleting your file(s)"
        );
        console.log(err);
      }
    );
  };

  const convertFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const fetchCompanyDocuments = () => {
    asyncAPICall(
      "/document/company-documents",
      "GET",
      null,
      null,
      (data) => {
        setCompanyFiles([]);

        return setCompanyFiles(data);
      },
      (err) => {
        errorToastNotification(
          "something went wrong while uploading your files."
        );
        console.log(err);
      }
    );
  };

  const fetchClientDocuments = useCallback(() => {
    if (loggedInUser?.role === "super-admin") {
      asyncAPICall(
        "/document/all-client-documents/recent",
        "GET",
        null,
        null,
        (data) => {
          setClientFiles(data);

          return setRecentDocuments([]);
        },
        (err) => {
          console.warn("Error Getting Documents: ", err);
        }
      );
    } else {
      asyncAPICall(
        "/document/client-documents/recent",
        "GET",
        null,
        null,
        (data) => {
          setClientFiles(data);

          return setRecentDocuments([]);
        },
        (err) => {
          errorToastNotification(
            "something went wrong while uploading your files."
          );
          console.log(err);
        }
      );
    }
  }, [loggedInUser?.role]);

  async function createDownloadLink() {
    const tempArr = [...recentDocuments];
    const newArr = await Promise.all(
      tempArr.map(async (doc) => {
        let response = await fetch(doc.secure_url);
        let objJsonStr = await response.text();

        try {
          decodeURIComponent(objJsonStr);
        } catch {
          return doc;
        }

        let splitUrl = objJsonStr.split(",");
        let encodedString = encodeURIComponent(splitUrl[1]);
        let finalUrl = `${splitUrl[0]},${encodedString}`;
        doc.data_url = finalUrl;
        return doc;
      })
    );

    setfileObjects(newArr);
  }

  useDeepEffect(() => {
    createDownloadLink();
  }, [recentDocuments]);

  useDeepEffect(() => {
    let clonedArray = JSON.parse(JSON.stringify(clientFiles));
    clonedArray.reverse();
    if (clonedArray?.length > 0 && clonedArray?.length < 6) {
      setRecentDocuments(clonedArray);
    } else if (clonedArray?.length > 0) {
      const docs = clonedArray.slice(0, 6);
      setRecentDocuments(docs);
    }
  }, [clientFiles]);

  useDeepEffect(() => {
    if (recentDocuments.length === 0 && clientFiles.length > 0) {
      let clonedArray = JSON.parse(JSON.stringify(clientFiles));
      clonedArray.reverse();
      if (clonedArray?.length > 0 && clonedArray?.length < 6) {
        setRecentDocuments(clonedArray);
      } else if (clonedArray?.length > 0) {
        const docs = clonedArray.slice(0, 6);
        setRecentDocuments(docs);
      }
    }
  }, [recentDocuments, clientFiles]);

  useDeepEffect(() => {
    if (overviewInfo?.bottom_left_description && !isSaveEdit) {
      const tempDict = overviewInfo;
      let textToParse = tempDict?.bottom_left_description;
      const geturl = new RegExp(
        "(^|[ \\t\\r\\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))",
        "g"
      );
      const linkArray = textToParse.match(geturl);
      let uniqueLinks = [...new Set(linkArray)];

      const newLinksArray = uniqueLinks.map((link) => {
        const finalArray = [];
        if (link.includes("=LINKNAME=")) {
          const linkSubArray = link.split("=LINKNAME=");
          const stringReplace = linkSubArray[1]?.replaceAll("&", " ");
          finalArray.push(linkSubArray[0]);
          finalArray.push(stringReplace);
        } else {
          finalArray.push(link);
          finalArray.push(link);
        }
        return finalArray;
      });
      let newArray = [];

      newArray.push(textToParse);

      uniqueLinks?.forEach((link, idx) => {
        const count = linkArray.filter((l) => l === link).length;
        for (let i = 0; i < count; i++) {
          if (newArray?.length > 0) {
            let newString = newArray?.slice(-1);
            newArray = newArray.slice(0, -1);

            try {
              let splitArray = newString[0].split(
                new RegExp(`${link}(.*)`, "s")
              );
              splitArray = splitArray.slice(0, -1);

              splitArray.splice(
                1,
                0,
                <a
                  key={`${idx}${link}`}
                  href={newLinksArray[idx][0]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {newLinksArray[idx][1]}
                </a>
              );
              newArray.push(...splitArray);
            } catch {
              console.log("Error Creating Hyperlinks");
            }
          }
        }
      });

      setParsedHtmlLeft(newArray);
    }
  }, [overviewInfo, isSaveEdit]);

  useDeepEffect(() => {
    if (overviewInfo?.bottom_right_description && !isSaveEdit) {
      const tempDict = overviewInfo;
      let textToParse = tempDict?.bottom_right_description;
      const geturl = new RegExp(
        "(^|[ \\t\\r\\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))",
        "g"
      );
      const linkArray = textToParse.match(geturl);
      let uniqueLinks = [...new Set(linkArray)];

      const newLinksArray = uniqueLinks.map((link) => {
        const finalArray = [];
        if (link.includes("=LINKNAME=")) {
          const linkSubArray = link.split("=LINKNAME=");
          const stringReplace = linkSubArray[1]?.replaceAll("&", " ");
          finalArray.push(linkSubArray[0]);
          finalArray.push(stringReplace);
        } else {
          finalArray.push(link);
          finalArray.push(link);
        }
        return finalArray;
      });

      let newArray = [];

      newArray.push(textToParse);

      uniqueLinks?.forEach((link, idx) => {
        const count = linkArray.filter((x) => x === link).length;
        for (let i = 0; i < count; i++) {
          if (newArray?.length > 0) {
            let newString = newArray?.slice(-1);
            newArray = newArray.slice(0, -1);

            try {
              let splitArray = newString[0].split(
                new RegExp(`${link}(.*)`, "s")
              );
              splitArray = splitArray.slice(0, -1);

              splitArray.splice(
                1,
                0,
                <a
                  key={`${link}${idx}`}
                  href={newLinksArray[idx][0]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {newLinksArray[idx][1]}
                </a>
              );
              newArray.push(...splitArray);
            } catch {
              console.log("Error Creating Hyperlinks");
            }
          }
        }
      });

      setParsedHtmlRight(newArray);
    }
  }, [overviewInfo, isSaveEdit]);

  useEffect(() => {
    asyncAPICall(
      "/user/get",
      "GET",
      null,
      null,
      (data) => setNewUsersData(data),
      (err) => console.warn("Error Getting All Users: ", err),
      true
    );
  }, []);

  useDeepEffect(() => {
    if (newUsersData?.length > 0) {
      const newUsersArray = newUsersData.filter((user) => {
        const date = new Date(user.created_date);
        const today = new Date();
        let difference = date.getTime() - today.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays >= -7;
      });

      setTotalUsersCount(newUsersData?.length);
      setNewUsersCount(newUsersArray?.length);
    }
  }, [newUsersData]);

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      fetchCompanyDocuments();
      fetchClientDocuments();
    }
  }, [userData, fetchClientDocuments]);

  useDeepEffect(() => {
    if (loggedInUser?.org_id) {
      asyncAPICall(
        `/organization/get/${loggedInUser.org_id}`,
        "GET",
        null,
        null,
        (data) => {
          setLoggedInUserOrganization(data);
        },
        (err) => {
          errorToastNotification(
            "something went wrong while retrieving companies."
          );
          console.log(err);
        }
      );
    }
  }, [loggedInUser]);

  const updateMessages = () => {
    const setState = new Promise((resolve, reject) => {
      try {
        resolve(setIsSaveEdit(!isSaveEdit));
      } catch {
        reject("Failed to update isSaveEdit");
      }
    });

    setState.then(() => {
      if (isSaveEdit) {
        asyncAPICall(
          "/overview-info/update",
          "POST",
          overviewInfo,
          (res) => {},
          null,
          (err) => console.log("Set message error: ", err)
        );
      }
    });
  };

  useEffect(() => {
    asyncAPICall(
      "/overview-info/get",
      "GET",
      null,
      null,
      (data) => {
        setOverviewInfo(null);
        return setOverviewInfo(data);
      },
      (err) => console.log("Set message error: ", err)
    );
  }, []);

  useEffect(() => {
    if (clientModal === true) {
      modalRef.current = true;
    }

    return () => (modalRef.current = false);
  }, [clientModal]);

  return (
    <div className="overview-page-container">
      <FilesModal
        comp="client"
        showModal={clientModal}
        setShowModal={setClientModal}
        handleUploadFile={handleUploadFile}
        fetchDocuments={fetchClientDocuments}
        organizations={organizations}
        setLoadingForAddDocs={setLoadingForAddDocs}
      />

      <div className="top-section">
        <div className="heading-wrapper">
          <div className="heading-title">Overview</div>
          <div className="heading-subtitle">
            {loggedInUser?.organization
              ? loggedInUser.organization.name
              : "N/A"}
          </div>
        </div>

        <div className="btns-wrapper">
          {userRole === "super-admin" ? (
            <button
              onClick={() => {
                updateMessages();
              }}
            >
              {isSaveEdit ? "Save" : "Edit"}
            </button>
          ) : null}

          {userRole !== "super-admin" ? (
            <a
              href="https://tamsolutions.com/contact/"
              target="_blank"
              rel="noreferrer"
              style={{ all: "unset" }}
            >
              <button>Contact Us</button>
            </a>
          ) : null}

          {userRole === "super-admin" ? (
            <button onClick={() => setClientModal(true)}>Uploads</button>
          ) : null}
        </div>
      </div>

      <div className="bottom-section">
        <div className="left-section">
          <div className="left-top">
            <div className="title">Client Option</div>
            <div className="textarea-wrapper">
              <div className="client-option-info">
                {loggedInUser?.organization &&
                loggedInUser?.organization.cv_option !== ""
                  ? loggedInUser.organization.cv_option
                  : "N/A"}
              </div>
            </div>
          </div>

          <div className="left-middle">
            <div className="title">
              {loggedInUser?.organization &&
              loggedInUser?.organization.cv_code === "TAMSLLC"
                ? "Portal Users"
                : "Users in Your Organization"}
            </div>
            <div className="textarea-wrapper">
              <div className="client-option-info">
                <div className="total-users">
                  <div className="category-title">Total Users</div>
                  {totalUsersCount}
                </div>
                <div className="new-users">
                  <div className="category-title">New Users</div>
                  {newUsersCount}
                </div>
              </div>
            </div>
          </div>

          <div className="left-bottom">
            <input
              className="title-input"
              type="text"
              placeholder="Enter Title"
              value={
                overviewInfo?.bottom_left_title
                  ? overviewInfo.bottom_left_title
                  : overViewValues.bottom_left_title
              }
              onChange={(e) =>
                setOverviewInfo((o) => {
                  return { ...o, bottom_left_title: e.target.value };
                })
              }
              disabled={!isSaveEdit}
            />
            <div className="textarea-wrapper">
              {isSaveEdit ? (
                <textarea
                  type="text"
                  placeholder="Enter Information"
                  className="enter-information"
                  value={
                    overviewInfo?.bottom_left_description
                      ? overviewInfo.bottom_left_description
                      : overViewValues.bottom_left_description
                  }
                  onChange={(e) =>
                    setOverviewInfo((o) => {
                      return {
                        ...o,
                        bottom_left_description: e.target.value,
                      };
                    })
                  }
                  disabled={!isSaveEdit}
                />
              ) : (
                <div className="entered-information">
                  <div className="parsed-text">{parsedHtmlLeft}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="right-section">
          <div className="right-top">
            <div className="title">
              Recent Documents{" "}
              <LoadingSpinner
                loading={loadingForAddDocs ? "loading" : "loaded"}
              />
            </div>
            <div className="list-documents">
              {recentDocuments.length > 0 ? (
                <ul>
                  {fileObjects.length >= recentDocuments.length
                    ? recentDocuments.map((file, idx) => {
                        return (
                          <li key={file.document_id}>
                            <div className="link-wrapper">
                              <a
                                href={
                                  fileObjects[idx]?.data_url
                                    ? fileObjects[idx]?.data_url
                                    : fileObjects[idx]?.secure_url
                                }
                                download={fileObjects[idx]?.name}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {fileObjects[idx]?.name}
                              </a>
                            </div>
                          </li>
                        );
                      })
                    : null}
                </ul>
              ) : (
                <div className="no-documents">No Recent Documents</div>
              )}
            </div>
          </div>

          <div className="right-bottom">
            <input
              className="title-input"
              type="text"
              placeholder="Enter Title"
              value={
                overviewInfo?.bottom_right_title
                  ? overviewInfo.bottom_right_title
                  : overViewValues.bottom_right_title
              }
              onChange={(e) =>
                setOverviewInfo((o) => {
                  return { ...o, bottom_right_title: e.target.value };
                })
              }
              disabled={!isSaveEdit}
            />

            <div className="textarea-wrapper">
              {isSaveEdit ? (
                <textarea
                  type="text"
                  placeholder="Enter Information"
                  className="enter-information"
                  value={
                    overviewInfo?.bottom_right_description
                      ? overviewInfo.bottom_right_description
                      : overViewValues.bottom_right_description
                  }
                  onChange={(e) =>
                    setOverviewInfo((o) => {
                      return {
                        ...o,
                        bottom_right_description: e.target.value,
                      };
                    })
                  }
                  disabled={!isSaveEdit}
                />
              ) : (
                <div className="entered-information">
                  <div className="parsed-text">{parsedHtmlRight}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewPage;
