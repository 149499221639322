import { useEffect, useState, useRef, useCallback } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink } from "react-router-dom";

import SecurityWrapper from "../util/securityWrapper";
import asyncAPICall from "../util/apiWrapper";

import { destructionCertificateColumns } from "../util/codStaticData";

import {
  errorToastNotification,
  successfulToast,
} from "../util/toastNotifications";
import CodPdfModal from "../components/modals/CodPdfModal";
import Clipboard from "../img/clipboard.svg";
import ImgIcon from "../img/img-icon.svg";
import LoadingSpinner from "../components/LoadingSpinner";

export default function DocumentsPage(props) {
  const [showModal, setShowModal] = useState(false);
  const [codQueue, setCodQueue] = useState([]);
  const [codInvRecords, setCodInvRecords] = useState([]);
  const [groupedCodInvRecords, setGroupedCodInvRecords] = useState([]);
  const [groupLegend, setGroupLegend] = useState([]);
  const [companyNameArray, setCompanyNameArray] = useState([]);
  const [gatheredCompanyNames, setGatheredCompanyNames] = useState(false);
  const [gatheringDataByPtId, setGatheringDataByPtId] = useState([]);
  const [gatheredDataByPtId, setGatheredDataByPtId] = useState(false);
  const [autoPdfVisibility, setAutoPdfVisibility] = useState(false);
  const [autoDataByPtId, setAutoDataByPtId] = useState([]);
  const [currentIteration, setCurrentIteration] = useState(-1);
  const [storedCods, setStoredCods] = useState([]);
  const [trashRemoved, setTrashRemoved] = useState(false);
  const [loadingBarWidth, setLoadingBarWidth] = useState(0);
  const [codLoading, setCodLoading] = useState(false);
  const [pdfsUploaded, setPdfsUploaded] = useState(0);
  const [callResponseData, setCallResponseData] = useState([]);
  const [initialStoredCods, setInitialStoredCods] = useState([]);
  const [fullStorageLength, setFullStorageLength] = useState(0);
  const [noPointerEvents, setNoPointerEvents] = useState(false);
  const [comparisonLength, setComparisonLength] = useState(0);
  const [hadRefreshError, setHadRefreshError] = useState(false);
  const [numberOfStoredManualCods, setNumberOfStoredManualCods] = useState(0);
  const [numberOfStoredAutoCods, setNumberOfStoredAutoCods] = useState(0);
  const [documentsData, setDocumentsData] = useState([]);
  const [docsWithUris, setDocsWithUris] = useState([]);
  const [clientFiles, setClientFiles] = useState([]);
  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [arrayWithDuplicates, setArrayWithDuplicates] = useState([]);
  const [deleteFiles, setDeleteFiles] = useState(false);
  const [numSelected, setNumSelected] = useState(0);
  const [codPageChange, setCODPageChange] = useState(1);
  const [docPageChange, setDocPageChange] = useState(1);
  const [codPageIsLoading, setCODPageIsLoading] = useState("not loading");
  const [docPageIsLoading, setDocPageIsLoading] = useState("not loading");

  const { cvCode, loggedInUser } = props;

  const modalLocation = useRef("documents");
  const mountedRef = useRef(false);
  const deleteRef = useRef(0);
  const linkRef = useRef();

  const documentsColumns =
    loggedInUser.role === "super-admin"
      ? [
          {
            selector: (row) => row.docIcon,
            minWidth: "15%",
            style: {
              justifyContent: "flex-start",
              paddingLeft: "1.5rem",
            },
          },
          {
            selector: (row) => row.docTitle,
            minWidth: "62%",
            allowOverflow: true,
            style: {
              justifyContent: "flex-start",
              paddingLeft: "1.5rem",
              overflowX: "auto",
            },
          },
          {
            selector: (row) => row.createdDate,
            minWidth: "22%",
            style: {
              justifyContent: "flex-start",
              paddingLeft: "1vw",
            },
          },
        ]
      : [
          {
            selector: null,
            minWidth: "max(2.75vw, 50px)",
            style: {
              justifyContent: "flex-start",
            },
          },
          {
            selector: (row) => row.docIcon,
            minWidth: "15%",
            style: {
              justifyContent: "flex-start",
              paddingLeft: "1.5rem",
            },
          },
          {
            selector: (row) => row.docTitle,
            minWidth: "62%",
            allowOverflow: true,
            style: {
              justifyContent: "flex-start",
              paddingLeft: "1.5rem",
              overflowX: "auto",
            },
          },
          {
            selector: (row) => row.createdDate,
            minWidth: "22%",
            style: {
              justifyContent: "flex-start",
              paddingLeft: "1vw",
            },
          },
        ];

  const handleClearRows = () => {
    setToggledClearRows((tCr) => !tCr);
  };

  const handleCodPdfModal = (rowData) => {
    if (mountedRef.current) {
      window.open(
        `https://res.cloudinary.com/duwet11zt/image/upload/${rowData.public_id}.pdf`
      );
    }
  };

  const codCheck = useCallback(() => {
    if (mountedRef.current) {
      asyncAPICall(
        `/get-received-cod-data`,
        "GET",
        null,
        null,
        (data) => {
          if (mountedRef.current) {
            asyncAPICall(
              `/cod-check/${modalLocation.current}`,
              "POST",
              data.cod_data,
              null,
              (data) => {
                if (mountedRef.current) {
                  setCodQueue(data);
                  asyncAPICall(
                    "/get-cod-inv-records",
                    "POST",
                    data,
                    null,
                    (data) => {
                      if (mountedRef.current) {
                        data.record_data.forEach((item) => {
                          const regex = new RegExp(
                            "(^\\w+)(\\s+)(\\d{1,2})(\\s)(\\d{4})",
                            "gm"
                          );
                          const str = `${item.dateReceived}`;
                          let m;
                          const matchArray = [];

                          while ((m = regex.exec(str)) !== null) {
                            if (m.index === regex.lastIndex) {
                              regex.lastIndex++;
                            }

                            m.forEach((match, groupIndex) => {
                              matchArray.push(match);
                            });
                          }

                          item.dateReceived = matchArray[0];
                        });
                        setCodInvRecords(data.record_data);
                      }
                    },
                    (err) => {
                      if (mountedRef.current) {
                        if (codQueue?.length > 0) {
                          console.error("codCheck Error Level 3: ", err);
                        } else {
                          const resetTimeout = setTimeout(() => {
                            setComparisonLength(0);
                            setNoPointerEvents(false);
                            setCodLoading(false);
                            setLoadingBarWidth(0);
                            setCurrentIteration(-1);
                            setCodQueue([]);
                            setCodInvRecords([]);
                            setGroupedCodInvRecords([]);
                            setCallResponseData([]);
                            setGroupLegend([]);
                            setCompanyNameArray([]);
                            setGatheredCompanyNames(false);
                            setGatheringDataByPtId([]);
                            setGatheredDataByPtId(false);
                            setAutoDataByPtId([]);
                            setPdfsUploaded(0);
                          }, 2000);

                          return () => clearTimeout(resetTimeout);
                        }
                      }
                    },
                    true,
                    null,
                    false
                  );
                }
              },
              (err) => {
                if (mountedRef.current) {
                  console.error("codCheck Error Level 2: ", err);
                }
              },
              true,
              null,
              false
            );
          }
        },
        (err) => {
          if (mountedRef.current) {
            console.error("codCheck Error Level 1: ", err);
          }
        },
        true,
        null
      );
    }
  }, [codQueue?.length]);

  const getCompanyName = useCallback(
    (index) => {
      if (mountedRef.current) {
        if (codQueue[0]) {
          asyncAPICall(
            "/get-company-name",
            "POST",
            codQueue[index],
            null,
            (data) => {
              if (mountedRef.current) {
                setCompanyNameArray((arr) => [...arr, data]);
              }
            },
            (err) => {
              if (mountedRef.current) {
                console.error("company name Error: ", err);
              }
            },
            true
          );
        }
      }
    },
    [codQueue]
  );

  const refreshCods = useCallback(async () => {
    if (mountedRef.current) {
      setNoPointerEvents(true);
      const refreshTimeout = setTimeout(async () => {
        asyncAPICall(
          `/get-cod-links/${cvCode}/${codPageChange}`,
          "GET",
          null,
          null,
          (data) => {
            if (mountedRef.current) {
              data.forEach((item) => {
                item.created_date = `${item.created_date.slice(
                  3,
                  5
                )}/${item.created_date.slice(0, 2)}${item.created_date.slice(
                  5,
                  10
                )}`;
              });
              setStoredCods(data);
              if (hadRefreshError) {
                setHadRefreshError(false);
              }
              setNoPointerEvents(false);
            }
          },
          (err) => {
            if (mountedRef.current) {
              setHadRefreshError(true);
            }
          },
          true,
          null,
          false
        );
      }, 2000);
      return () => clearTimeout(refreshTimeout);
    }
  }, [hadRefreshError, cvCode, codPageChange]);

  function downloadFile(file) {
    const link = linkRef.current;

    link.href = file.uriLink;
    link.download = file.docTitle.props.children;

    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
  }

  const handleRowClicked = (rowData) => {
    if (mountedRef.current) {
      if (rowData.uriLink.includes("https")) {
        window.open(rowData.docLink);
      } else {
        downloadFile(rowData);
      }
    }
  };

  const handleSelectionChange = useCallback((rowData) => {
    const unfilteredArray = rowData.selectedRows.map((record) => {
      return record.docId;
    });
    setNumSelected(unfilteredArray.length);
    setArrayWithDuplicates(unfilteredArray);
  }, []);

  const handleDeleteFiles = useCallback(() => {
    setDeleteFiles(true);
    if (arrayWithDuplicates.length > 0) {
      arrayWithDuplicates.forEach((item) => {
        asyncAPICall(
          `/document/delete/${item}`,
          "DELETE",
          null,
          null,
          () => {
            deleteRef.current += 1;
            setDocumentsData((c) => c.filter((f) => f.docId !== item));
            setDocsWithUris((dWu) => dWu.filter((f) => f.docId !== item));
            successfulToast("File(s) Deleted Successfully");
            setDeleteFiles(false);
          },
          (err) => {
            errorToastNotification(
              "something went wrong with deleting your file(s)"
            );
            setDeleteFiles(false);
            console.warn(err);
          }
        );
      });

      if (deleteRef.current === arrayWithDuplicates.length) {
        deleteRef.current = 0;
        handleClearRows();
      }
    }
  }, [arrayWithDuplicates]);

  async function createDownloadLink(url) {
    let response = await fetch(url);
    let objJsonStr = await response.text();

    try {
      decodeURIComponent(objJsonStr);
    } catch {
      return url;
    }

    let splitUrl = objJsonStr.split(",");
    let encodedString = encodeURIComponent(splitUrl[1]);
    let finalUrl = `${splitUrl[0]},${encodedString}`;
    return finalUrl;
  }

  useEffect(() => {
    setDocsWithUris([]);
    const docsData = [...documentsData];
    docsData.forEach((file) => {
      createDownloadLink(file.docLink).then((res) => {
        file.uriLink = res;
      });
    });

    setDocsWithUris((dWu) => {
      let tempArr = [...dWu];

      docsData.forEach((file) => {
        if (tempArr.length >= documentsData.length) {
          let arrayToSort = [...tempArr];
          const sortedArray = arrayToSort.sort((a, b) => {
            let keyA = new Date(a.fullDate),
              keyB = new Date(b.fullDate);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          tempArr = sortedArray.reverse();
        } else {
          let arrayToSort = [...tempArr, file];
          const sortedArray = arrayToSort.sort((a, b) => {
            let keyA = new Date(a.fullDate),
              keyB = new Date(b.fullDate);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          tempArr = sortedArray.reverse();
        }
      });

      return tempArr;
    });
  }, [documentsData]);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (hadRefreshError) {
      const errorTimeout = setTimeout(() => {
        setHadRefreshError(false);
        setNoPointerEvents(false);
      }, 2000);
      return () => clearTimeout(errorTimeout);
    }
  }, [hadRefreshError]);

  useEffect(() => {
    if (codQueue?.length > 0) {
      setCodLoading(true);
      let newWidth;
      if (pdfsUploaded === 0) {
        newWidth = 0;
      } else {
        newWidth = comparisonLength / groupedCodInvRecords?.length;
      }
      setLoadingBarWidth(newWidth);
    } else {
      setCodLoading(false);
      setNoPointerEvents(false);
    }
  }, [
    codQueue?.length,
    groupedCodInvRecords,
    groupedCodInvRecords?.length,
    pdfsUploaded,
    callResponseData,
    callResponseData?.length,
    loadingBarWidth,
    storedCods,
    storedCods?.length,
    fullStorageLength,
    comparisonLength,
  ]);

  useEffect(() => {
    if (initialStoredCods?.length > 0 && storedCods?.length > 0) {
      const tempComparisonLength =
        storedCods?.length - initialStoredCods?.length;
      setComparisonLength(tempComparisonLength);
    }
    if (
      comparisonLength === groupedCodInvRecords?.length &&
      comparisonLength !== 0
    ) {
      const resetTimeout = setTimeout(() => {
        setComparisonLength(0);
        setNoPointerEvents(false);
        setCodLoading(false);
        setLoadingBarWidth(0);
        setCurrentIteration(-1);
        setCodQueue([]);
        setCodInvRecords([]);
        setGroupedCodInvRecords([]);
        setCallResponseData([]);
        setGroupLegend([]);
        setCompanyNameArray([]);
        setGatheredCompanyNames(false);
        setGatheringDataByPtId([]);
        setGatheredDataByPtId(false);
        setAutoDataByPtId([]);
        setPdfsUploaded(0);
      }, 2000);

      return () => clearTimeout(resetTimeout);
    }
  }, [
    comparisonLength,
    loadingBarWidth,
    storedCods,
    storedCods?.length,
    initialStoredCods,
    initialStoredCods?.length,
    groupedCodInvRecords,
    groupedCodInvRecords?.length,
  ]);

  useEffect(() => {
    if (mountedRef.current && cvCode) {
      setCODPageIsLoading("loading");
      asyncAPICall(
        `/get-cod-links/${cvCode}/${codPageChange}`,
        "GET",
        null,
        null,
        (data) => {
          if (mountedRef.current) {
            setCODPageIsLoading("not loading");
            data.forEach((item) => {
              item.created_date = `${item.created_date.slice(
                3,
                5
              )}/${item.created_date.slice(0, 2)}${item.created_date.slice(
                5,
                10
              )}`;
            });
            setInitialStoredCods(data);
          }
        },
        (err) => {
          if (mountedRef.current) {
            setCODPageIsLoading("not loading");
            console.error("Stored CODs Error: ", err);
          }
        },
        true,
        null,
        false
      );
    }
  }, [cvCode, codPageChange]);

  useEffect(() => {
    if (initialStoredCods?.length > 0 && groupedCodInvRecords?.length > 0) {
      const fullLength =
        initialStoredCods?.length + groupedCodInvRecords?.length;
      setFullStorageLength(fullLength);
    }
  }, [initialStoredCods, groupedCodInvRecords]);

  useEffect(() => {
    if (mountedRef.current && cvCode) {
      setCODPageIsLoading("loading");
      asyncAPICall(
        `/get-cod-links/${cvCode}/${codPageChange}`,
        "GET",
        null,
        null,
        (data) => {
          if (mountedRef.current) {
            setCODPageIsLoading("not loading");
            data.forEach((item) => {
              item.created_date = `${item.created_date.slice(
                3,
                5
              )}/${item.created_date.slice(0, 2)}${item.created_date.slice(
                5,
                10
              )}`;
            });
            setStoredCods(data);
          }
        },
        (err) => {
          if (mountedRef.current) {
            setCODPageIsLoading("not loading");
            console.error("Stored CODs Error: ", err);
          }
        },
        true,
        null,
        false
      );
    }
  }, [
    callResponseData,
    callResponseData?.length,
    cvCode,
    initialStoredCods?.length,
    codPageChange,
  ]);

  useEffect(() => {
    if (trashRemoved === "success") {
      codCheck();
      setTrashRemoved(false);
    } else if (trashRemoved === "failed") {
      console.error("Failed Trash Removal");
      setTrashRemoved(false);
    } else {
      setTrashRemoved(false);
    }
  }, [trashRemoved, codCheck]);

  useEffect(() => {
    if (gatheredDataByPtId && !autoPdfVisibility) {
      setCurrentIteration((cI) => (cI += 1));
    }
  }, [gatheredDataByPtId, autoPdfVisibility]);

  useEffect(() => {
    if (
      currentIteration !== -1 &&
      currentIteration < groupedCodInvRecords?.length
    ) {
      setAutoPdfVisibility(true);
    }
  }, [currentIteration, groupedCodInvRecords, groupedCodInvRecords?.length]);

  useEffect(() => {
    if (!gatheredCompanyNames) {
      setAutoPdfVisibility(false);
      setCodLoading(false);
      setLoadingBarWidth(0);
    }
  }, [gatheredCompanyNames]);

  useEffect(() => {
    if (codQueue[0]) {
      if (codQueue?.length > companyNameArray?.length) {
        const iteration = (codQueue?.length - companyNameArray?.length) * -1;
        const iterationIndex = codQueue?.length + iteration;
        getCompanyName(iterationIndex);
      } else {
        setGatheredCompanyNames(true);
      }
    }
  }, [
    codQueue,
    codQueue?.length,
    companyNameArray,
    companyNameArray?.length,
    getCompanyName,
  ]);

  useEffect(() => {
    if (codInvRecords[0])
      codQueue.forEach((cod) => {
        setGroupLegend((arr) => [...arr, cod.poId]);
      });
  }, [codInvRecords, codQueue]);

  useEffect(() => {
    if (groupLegend?.length === codQueue?.length) {
      groupLegend.forEach((poId) => {
        const tempArray = codInvRecords.filter((record) => {
          return record.poId === poId;
        });
        setGroupedCodInvRecords((arr) => [...arr, tempArray]);
      });
    }
  }, [groupLegend?.length, codQueue?.length, codInvRecords, groupLegend]);

  useEffect(() => {
    if (codQueue[0] && groupedCodInvRecords[0] && gatheredCompanyNames) {
      const range = codQueue.length;
      for (let i = 0; i < range; i++) {
        const tempObj = codQueue[i];
        tempObj["companyName"] = companyNameArray[i];
        tempObj["invRecords"] = groupedCodInvRecords[i];
        setGatheringDataByPtId((arr) => [...arr, tempObj]);
      }
      setGatheredDataByPtId(true);
    }
  }, [
    codQueue,
    codQueue?.length,
    groupedCodInvRecords,
    gatheredCompanyNames,
    companyNameArray,
  ]);

  useEffect(() => {
    if (gatheredDataByPtId) {
      setAutoDataByPtId(gatheringDataByPtId);
    }
  }, [gatheredDataByPtId, gatheringDataByPtId]);

  useEffect(() => {
    if (autoDataByPtId[0]) setAutoPdfVisibility(true);
  }, [autoDataByPtId]);

  useEffect(() => {
    const manualCods = [];
    const autoCods = [];

    if (storedCods) {
      storedCods.forEach((cod) => {
        if (cod.cod_id.length >= 12) {
          manualCods.push(cod);
        } else {
          autoCods.push(cod);
        }
      });
    }

    if (manualCods?.length > 0) {
      setNumberOfStoredManualCods(manualCods.length);
    }

    if (autoCods?.length > 0) {
      setNumberOfStoredAutoCods(autoCods.length);
    }
  }, [storedCods, storedCods?.length]);

  useEffect(() => {
    if (!deleteFiles) {
      setDocPageIsLoading("loading");
      handleClearRows();
      if (loggedInUser?.role === "super-admin") {
        asyncAPICall(
          `/document/all-client-documents/${docPageChange}`,
          "GET",
          null,
          null,
          (data) => {
            setDocPageIsLoading("not loading");
            setClientFiles(data);
          },
          (err) => {
            setDocPageIsLoading("not loading");
            console.warn("Error Getting Documents: ", err);
          }
        );
      } else {
        asyncAPICall(
          `/document/client-documents/${docPageChange}`,
          "GET",
          null,
          null,
          (data) => {
            setDocPageIsLoading("not loading");
            setClientFiles(data);
          },
          (err) => {
            setDocPageIsLoading("not loading");
            console.warn("Error Getting Documents: ", err);
          }
        );
      }
    }
  }, [deleteFiles, loggedInUser?.role, docPageChange]);

  useEffect(() => {
    if (clientFiles?.length > 0) {
      const data = clientFiles.map((file) => {
        const createdDate = file.created_date;
        const month = createdDate.slice(5, 7);
        const day = createdDate.slice(8, 10);
        const year = createdDate.slice(0, 4);

        return {
          docIcon: (
            <img
              src={
                ["jpeg", "tiff", "webp", "avif"].includes(file.name.slice(-4))
                  ? ImgIcon
                  : ["png", "jpg", "tif", "gif", "svg"].includes(
                      file.name.slice(-3)
                    )
                  ? ImgIcon
                  : Clipboard
              }
              alt="file icon"
              data-tag="allowRowEvents"
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
          ),
          docTitle: <b data-tag="allowRowEvents">{file.name}</b>,
          createdDate: `${month}-${day}-${year}`,
          docLink: file.secure_url,
          docId: file.document_id,
          fullDate: file.created_date,
        };
      });
      const sortedArray = data.sort((a, b) => {
        let keyA = new Date(a.fullDate),
          keyB = new Date(b.fullDate);
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      sortedArray.reverse();

      setDocumentsData(sortedArray);
    }
  }, [clientFiles]);

  return (
    <div
      className="documents-page-wrapper"
      style={
        docPageIsLoading === "loading" || codPageIsLoading === "loading"
          ? { pointerEvents: "none" }
          : { pointerEvents: "all" }
      }
    >
      <h1 className="title">Documents</h1>
      <a ref={linkRef} style={{ display: "none" }}></a>
      <div className="header-wrapper">
        <div className="left-side">
          {2 === 3 ? (
            <>
              <button className="filter-btn">Filter</button>
              <button className="search-btn">Search</button>
            </>
          ) : null}

          <button
            className="cod-refresh-btn"
            style={
              noPointerEvents
                ? { pointerEvents: "none", backgroundColor: "#fbd4aa" }
                : { pointerEvents: "all" }
            }
            onClick={() => refreshCods()}
          >
            {noPointerEvents && !hadRefreshError
              ? "Refreshing..."
              : hadRefreshError
              ? "ERROR"
              : "Refresh CODs"}
          </button>
        </div>

        <div className="right-side">
          <SecurityWrapper restrict_roles="user">
            <SecurityWrapper restrict_roles="admin">
              <Link to="/purchase-order-management">
                <button className="purchase-orders-button">
                  Purchase Orders
                </button>
              </Link>
            </SecurityWrapper>
          </SecurityWrapper>
          {loggedInUser?.role === "super-admin" ? (
            <NavLink to="/documents-page" className="purchase-orders-nav-link">
              <button className="documents-button">Documents</button>
            </NavLink>
          ) : null}
        </div>
      </div>
      <div className="tables-container">
        <div className="destruction-reports-table-wrapper">
          <div className="sticky-header">
            <h3>Certificates of Destruction</h3>
            <LoadingSpinner loading={codPageIsLoading} />
          </div>
          <div className="horizontal-rule"></div>
          <div className="small-vertical-rule"></div>
          <div className="column-headers-container-wrapper">
            <div className="column-headers-container-left">
              <div className="column-header-wrapper-1">
                <h5 className="column-1">Issued Date</h5>
              </div>
              <div className="column-header-wrapper-2">
                <h5 className="column-2">Company Name</h5>
              </div>
              <div className="column-header-wrapper-3">
                <h5 className="column-3">COD ID</h5>
              </div>
              <div className="column-header-wrapper-4">
                <h5 className="column-4">Items Received</h5>
              </div>
            </div>
            <div className="column-headers-container-right">
              <div className="column-header-wrapper-file-type">
                <h5 className="column-1">File Type</h5>
              </div>
              <div className="column-header-wrapper-doc-title">
                <h5 className="column-2">Document Title</h5>
              </div>
              <div className="column-header-wrapper">
                <h5 className="column-3">Date Added</h5>
              </div>
            </div>
          </div>
          <DataTable
            columns={destructionCertificateColumns}
            data={storedCods.slice().reverse()}
            noHeader={true}
            onRowClicked={(rowData) => handleCodPdfModal(rowData)}
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={15}
            paginationRowsPerPageOptions={[15]}
            paginationServer
            paginationTotalRows={
              storedCods.length > 0 ? storedCods[0].total : 0
            }
            onChangePage={(page) => setCODPageChange(page)}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          />
        </div>

        <div className="vertical-rule"></div>

        <div className="documents-table-wrapper">
          <div className="sticky-header">
            <h3>Documents</h3>
            <LoadingSpinner loading={docPageIsLoading} posAbsolute />
          </div>
          {loggedInUser.role === "super-admin" ? (
            <button className="delete-button" onClick={handleDeleteFiles}>
              Delete Selected
            </button>
          ) : null}
          <DataTable
            columns={documentsColumns}
            data={docsWithUris}
            noHeader={true}
            onRowClicked={(rowData) => handleRowClicked(rowData)}
            highlightOnHover
            pointerOnHover
            pagination
            selectableRows={loggedInUser.role === "super-admin" ? true : false}
            onSelectedRowsChange={handleSelectionChange}
            selectableRowsNoSelectAll
            selectableRowsHighlight
            clearSelectedRows={toggledClearRows}
            paginationPerPage={15}
            paginationRowsPerPageOptions={[15]}
            paginationServer
            paginationTotalRows={
              clientFiles.length > 0 ? clientFiles[0].total_docs : 0
            }
            onChangePage={(page) => setDocPageChange(page)}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          />
        </div>
      </div>
      {showModal && (
        <CodPdfModal
          modalLocation={modalLocation.current}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
}
