import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Cookies from "js-cookie";
import Logo from "../img/logo.svg";
import logout from "../util/logout";
import { awaitAPICall } from "../util/apiWrapper";

const LoginPage = (props) => {
  const { setUserId, setLoginData } = props;
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");

  const sendTwoFactorToken = (userId) => {
    awaitAPICall(
      "/two-factor-auth",
      "POST",
      {
        email,
        user_id: userId,
      },
      (response) => {
        if (response.ok) {
          return response;
        }
      },
      null,
      (err) => {
        console.log(err);
      },
      false
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form_body = new FormData(e.target);

    awaitAPICall(
      "/user/auth",
      "POST",
      Object.fromEntries(form_body),
      (response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          const error_msg = "Invalid Email/Password";

          setErrorMsg(error_msg);

          return null;
        } else if (response.status === 403) {
          response.json().then((data) => setErrorMsg(data));

          return null;
        }
      },
      (data) => {
        if (data) {
          setLoginData(data);
          setUserId(data.user.user_id);
          sendTwoFactorToken(data.user.user_id);

          props.history.push("/login/two-factor-auth");
        }
      },
      null,
      false
    );
  };

  useEffect(() => {
    let auth_token = Cookies.get("auth_token");
    let expiration = Cookies.get("auth_expires");
    if (auth_token && Date.parse(expiration) < Date.now()) {
      return logout();
    }
  }, []);

  return (
    <div className="login-container">
      <img src={Logo} alt="tams header company logo" />

      <div className="login-wrapper">
        <form className="form-wrapper" onSubmit={handleSubmit} method="POST">
          <input
            id="email"
            name="email"
            type="email"
            placeholder="email@tamsolutions.com"
            className="login-input"
            autoComplete="username"
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            id="password"
            name="password"
            type="password"
            placeholder="********"
            className="login-input"
            autoComplete="password"
          />

          <div
            className="error-message"
            style={{
              position: "absolute",
              top: "55%",
              left: "49.5%",
              transform: "translate(-50%)",
              textAlign: "center",
            }}
          >
            {errorMsg}
          </div>

          <div />

          <div className="button-wrapper">
            <button className="confirm-button login-button" type="submit">
              Login
            </button>
            <div />

            <Link className="no-decoration" to="/login/password/recovery">
              RESET PASSWORD
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
