import { useEffect, useState } from "react";
import Logo from "../img/logo.svg";
import asyncAPICall from "../util/apiWrapper";

const ChangePassword = (props) => {
  const [userToken, setUserToken] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const { token } = props.match.params;

  const { mode } = props;

  const routeChange = (e, route = "/login") => {
    e.preventDefault();

    let body;

    if (mode === "create") {
      body = {
        token: userToken,
        new_password: password,
        mode: mode,
        email: userEmail,
      };
    } else {
      body = {
        token: userToken,
        new_password: password,
        mode: mode,
      };
    }

    asyncAPICall(
      "/user/forgot_password_change",
      "POST",
      body,
      (res) => {
        console.log(res.status);
        if (res.status === 200) {
          props.history.push(route);
        } else {
          if (mode === "create") {
            setErrorMessage("Password already created");
          } else {
            setErrorMessage("expired password reset link");
          }
        }
      },
      null,
      (err) => {
        console.log("updating password error", err);
      },
      false,
      {
        "content-type": "application/json",
      }
    );
  };

  useEffect(() => {
    if (userToken && mode === "change") {
      const body = {
        token: userToken,
        email_request: "email_request",
        mode: mode,
      };

      asyncAPICall(
        "/user/forgot_password_change",
        "POST",
        body,
        null,
        (data) => {
          setUserEmail(data);
        },
        (err) => {
          console.log("Error Getting User Email", err);
        },
        false,
        {
          "content-type": "application/json",
        }
      );
    }
  }, [userToken, mode]);

  useEffect(() => {
    const params = window.atob(token);
    const splitParams = params.split("&");
    const resetToken = splitParams[2].slice(splitParams[2].indexOf("=") + 1);
    setUserToken(resetToken);
    if (mode === "create") {
      const emailFromLink = splitParams[1].slice(
        splitParams[1].indexOf("=") + 1
      );
      setUserEmail(emailFromLink);
    }
  }, [token, mode]);

  useEffect(() => {
    if (password === verifyPassword && password !== "" && errorMessage === "") {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  }, [password, verifyPassword, errorMessage]);

  return (
    <div className="login-container">
      <img src={Logo} alt="tams header company logo" />

      <div className="change-password-wrapper">
        {mode === "change" ? (
          <h2>Change Password</h2>
        ) : (
          <h2>Create Password</h2>
        )}
        <p className="error-message">{errorMessage}</p>

        <form className="recovery-form">
          <input
            required
            className="send-recovery hidden"
            type="text"
            placeholder="Token"
            value={userToken}
            readOnly
          />

          <input
            required
            className="send-recovery"
            type="text"
            placeholder="Email"
            value={userEmail}
            readOnly
          />

          <input
            required
            className="send-recovery"
            type="password"
            placeholder="New Password"
            value={password}
            autoComplete="new-password"
            onChange={(e) => setPassword(e.target.value)}
          />

          <input
            required
            className="send-recovery"
            type="password"
            placeholder="Verify New Password"
            value={verifyPassword}
            onChange={(e) => setVerifyPassword(e.target.value)}
          />

          <button
            disabled={!passwordsMatch}
            className="confirm-button send-recovery"
            type="submit"
            onClick={(e) => routeChange(e)}
          >
            {mode === "change" ? "Change Password" : "Create Password"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
