import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Logo from "./img/header-logo.svg";
import logout from "./util/logout";

export default function Header(props) {
  let timer = null;

  useEffect(() => {
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timer]);

  return (
    <div className="navbar-wrapper">
      <div className="left-column">
        <Link className="logo-wrapper nav-item" to="/overview">
          <img src={Logo} alt="tams header company logo" height="18px"></img>
        </Link>
      </div>

      <div className="right-column">
        <Link to="/login" className="logout-link" onClick={() => logout(props)}>
          Logout
        </Link>
      </div>
    </div>
  );
}
