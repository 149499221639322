import React from "react";
import { useState } from "react";
import { Route } from "react-router-dom";

import ForgotPassword from "./forgotPassword/forgotPasswordPage";
import EmailSent from "./forgotPassword/emailSentPage";
import TempPassword from "./forgotPassword/tempPassword";
import ChangePassword from "./forgotPassword/changePassword";

import LoginPage from "./pages/loginPage";
import TwoFactor from "./pages/TwoFactor";

export default function LoginContainer(props) {
  const [userId, setUserId] = useState("");
  const [loginData, setLoginData] = useState({});
  return (
    <div className="wrapper">
      <div className="container">
        <Route
          exact
          path="/login"
          render={(routeProps) => (
            <LoginPage
              {...routeProps}
              authToken={props.authToken}
              setAuthToken={props.setAuthToken}
              setUserId={setUserId}
              setLoginData={setLoginData}
            />
          )}
        />
        <Route
          path="/login/two-factor-auth"
          render={() => <TwoFactor userId={userId} loginData={loginData} />}
        />

        <Route
          exact
          path="/login/password/recovery"
          component={ForgotPassword}
        />

        <Route path="/login/password/temporary" component={TempPassword} />

        <Route
          path="/login/password/change/:token"
          render={(props) => {
            return <ChangePassword {...props} mode={"change"} />;
          }}
        />
        <Route
          path="/login/password/create/:token"
          render={(props) => {
            return <ChangePassword {...props} mode={"create"} />;
          }}
        />
        <Route path="/login/email/sent" component={EmailSent} />
      </div>
    </div>
  );
}
