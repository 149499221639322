const destructionCertificateColumns = [
  {
    selector: (row) => row.created_date,
    minWidth: "20%",
    style: {
      justifyContent: "flex-start",
      paddingLeft: "1.5rem",
    },
  },
  {
    selector: (row) => row.cvcode,
    minWidth: "50%",
    style: {
      justifyContent: "flex-start",
    },
  },
  {
    selector: (row) => (row.cod_id?.length > 10 ? "Manual" : row.cod_id),
    minWidth: "12%",
    style: {
      justifyContent: "flex-start",
    },
  },
  {
    selector: (row) =>
      row.items_received === "0" ? "N/A" : row.items_received,
    minWidth: "18%",
    style: {
      justifyContent: "flex-start",
    },
  },
];

const purchaseOrderColumns = [
  {
    name: "CV Code",
    selector: (row) => row.cvcode,
    width: "12.5%",
    style: {},
  },
  {
    name: "PT ID",
    selector: (row) => row.ptId,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Purchase Date",
    selector: (row) => row.purchaseDate,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "Receive Status",
    selector: (row) => row.receivestatus,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
  {
    name: "User Defined 1",
    selector: (row) => row.userDefined1,
    width: "12.5%",
    style: {
      marginRight: "0.75rem",
    },
  },
];

const staticPoData = [
  {
    ptId: "31911",
    receivestatus: "Received",
    itemsReceived: 1,
    purchaseDate: "2022-10-12T00:00:00",
    cvcode: "ADOBE",
  },
  {
    ptId: "31928",
    receivestatus: "Received",
    itemsReceived: 20,
    purchaseDate: "2022-10-14T00:00:00",
    cvcode: "ADOBE",
  },

  {
    ptId: "31940",
    receivestatus: "Received",
    itemsReceived: 1,
    purchaseDate: "2022-10-17T00:00:00",
    cvcode: "ADOBE",
  },

  {
    ptId: "31942",
    receivestatus: "Received",
    itemsReceived: 2,
    purchaseDate: "2022-10-17T00:00:00",
    cvcode: "ADOBE",
  },

  {
    ptId: "31943",
    receivestatus: "Received",
    itemsReceived: 1,
    purchaseDate: "2022-10-17T00:00:00",
    cvcode: "ADOBE",
  },

  {
    ptId: "31946",
    receivestatus: "Received",
    itemsReceived: 5,
    purchaseDate: "2022-10-17T00:00:00",
    cvcode: "ADOBE",
  },

  {
    ptId: "31947",
    receivestatus: "Received",
    itemsReceived: 10,
    purchaseDate: "2022-10-17T00:00:00",
    cvcode: "ADOBE",
  },

  {
    ptId: "31949",
    receivestatus: "Received",
    itemsReceived: 3,
    purchaseDate: "2022-10-17T00:00:00",
    cvcode: "ADOBE",
  },

  {
    ptId: "31956",
    receivestatus: "Received",
    itemsReceived: 2,
    purchaseDate: "2022-10-18T00:00:00",
    cvcode: "ADOBE",
  },
  {
    ptId: "Mock Data",
    receivestatus: "Mock Data",
    itemsReceived: 0,
    purchaseDate: "Mock Data",
    cvcode: "ADOBE",
  },
  {
    ptId: "Mock Data",
    receivestatus: "Mock Data",
    itemsReceived: 0,
    purchaseDate: "Mock Data",
    cvcode: "ADOBE",
  },
  {
    ptId: "Mock Data",
    receivestatus: "Mock Data",
    itemsReceived: 0,
    purchaseDate: "Mock Data",
    cvcode: "ADOBE",
  },
  {
    ptId: "Mock Data",
    receivestatus: "Mock Data",
    itemsReceived: 0,
    purchaseDate: "Mock Data",
    cvcode: "ADOBE",
  },
  {
    ptId: "Mock Data",
    receivestatus: "Mock Data",
    itemsReceived: 0,
    purchaseDate: "Mock Data",
    cvcode: "ADOBE",
  },
  {
    ptId: "Mock Data",
    receivestatus: "Mock Data",
    itemsReceived: 0,
    purchaseDate: "Mock Data",
    cvcode: "ADOBE",
  },
  {
    ptId: "Mock Data",
    receivestatus: "Mock Data",
    itemsReceived: 0,
    purchaseDate: "Mock Data",
    cvcode: "ADOBE",
  },
  {
    ptId: "Mock Data",
    receivestatus: "Mock Data",
    itemsReceived: 0,
    purchaseDate: "Mock Data",
    cvcode: "ADOBE",
  },
  {
    ptId: "Mock Data",
    receivestatus: "Mock Data",
    itemsReceived: 0,
    purchaseDate: "Mock Data",
    cvcode: "ADOBE",
  },
  {
    ptId: "Mock Data",
    receivestatus: "Mock Data",
    itemsReceived: 0,
    purchaseDate: "Mock Data",
    cvcode: "ADOBE",
  },
];

const staticCodColumns = [
  {
    name: "Date Received",
    selector: (row) => row.dateReceived,
    width: "17%",
  },
  {
    name: "Selected Model",
    selector: (row) => row.selectedModel,
    width: "46%",
  },
  {
    name: "Asset Tag",
    selector: (row) => row.assetTag,
    width: "25%",
  },
  {
    name: "PO Line",
    selector: (row) => row.poLine,
    width: "12%",
  },
];

const staticCodData = [
  {
    dateReceived: "2022-10-12T10:03:03",
    selectedModel: "1TB SEGATE HDD",
    serialNumber: "9XG0HBRE.",
    assetTag: "N/A",
    ptId: "31911",
    ptLine: "1",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D087G4.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "1",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D08KF2.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "2",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D08JPW.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "3",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D0717R.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "4",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D071P1.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "5",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D06VYP.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "6",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D0JCKJ.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "7",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D0A1EZ.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "8",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D085E7.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "9",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D0JCH4.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "10",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D073FZ.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "11",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D0HZTC.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "12",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D03R3T.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "13",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D03R5Q.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "14",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D085GR.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "15",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D03R51.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "16",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D06ZY8.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "17",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D06PRM.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "18",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D087T5.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "19",
  },
  {
    dateReceived: "2022-10-14T09:04:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D0737L.",
    assetTag: "N/A",
    ptId: "31928",
    ptLine: "20",
  },
  {
    dateReceived: "2022-10-17T09:41:57",
    selectedModel: "1TB SEGATE HDD ",
    serialNumber: "9XG0D0M4 ",
    ptId: "31940",
    ptLine: "1",
  },
  {
    dateReceived: "2022-10-17T09:52:35",
    selectedModel:
      "Lot of 10 - Intel Xeon Processor E5-2630v3 SR206 2.40GHz 20M",
    serialNumber: "N/A",
    assetTag: "N/A",
    ptId: "31942",
    ptLine: "1",
  },
  {
    dateReceived: "2022-10-17T09:52:35",
    selectedModel:
      "Lot of 10 - Intel Xeon Processor E5-2630v3 SR206 2.40GHz 20M",
    serialNumber: "N/A",
    assetTag: "N/A",
    ptId: "31942",
    ptLine: "2",
  },
  {
    dateReceived: "2022-10-17T09:59:57",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D0HZQ6 ",
    assetTag: "N/A",
    ptId: "31943",
    ptLine: "1",
  },
  {
    dateReceived: "2022-10-17T10:48:53",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D0JCS8 ",
    assetTag: "N/A",
    ptId: "31946",
    ptLine: "1",
  },
  {
    dateReceived: "2022-10-17T10:48:53",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D073VF ",
    assetTag: "N/A",
    ptId: "31946",
    ptLine: "2",
  },
  {
    dateReceived: "2022-10-17T10:48:53",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D0JC03 ",
    assetTag: "N/A",
    ptId: "31946",
    ptLine: "3",
  },
  {
    dateReceived: "2022-10-17T10:48:53",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D08JMH ",
    assetTag: "N/A",
    ptId: "31946",
    ptLine: "4",
  },
  {
    dateReceived: "2022-10-17T10:48:53",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D0JC5T ",
    assetTag: "N/A",
    ptId: "31946",
    ptLine: "5",
  },
  {
    dateReceived: "2022-10-17T10:48:17",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D08JRV.",
    assetTag: "N/A",
    ptId: "31947",
    ptLine: "1",
  },
  {
    dateReceived: "2022-10-17T10:48:17",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D0HL86.",
    assetTag: "N/A",
    ptId: "31947",
    ptLine: "2",
  },
  {
    dateReceived: "2022-10-17T10:48:17",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D0BJQ8.",
    assetTag: "N/A",
    ptId: "31947",
    ptLine: "3",
  },
  {
    dateReceived: "2022-10-17T10:48:17",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D08KD2.",
    assetTag: "N/A",
    ptId: "31947",
    ptLine: "4",
  },
  {
    dateReceived: "2022-10-17T10:48:17",
    selectedModel: "6TB SEAGATE HDD",
    serialNumber: "Z4D01BV4.",
    assetTag: "N/A",
    ptId: "31947",
    ptLine: "5",
  },
];

export {
  destructionCertificateColumns,
  staticPoData,
  staticCodData,
  staticCodColumns,
  purchaseOrderColumns,
};
