import Modal from "react-modal";
import InventoryFilterForm from "../forms/InventoryFilterForm";

Modal.setAppElement("#root");

const InventoryModal = (props) => {
  return (
    <Modal
      isOpen={props.openModal ? true : false}
      onRequestClose={() => props.setOpenModal(false)}
      style={{
        overlay: {
          backdropFilter: "blur(2px)",
          backgroundColor: "rgb(49, 53, 62, 0.5)",
          zIndex: "2",
        },

        content: {
          border: "1px solid black",
          position: "absolute",
          width: "500px",
          height: "fit-content",
          top: "17%",
          left: "50%",
          transform: "translateX(-50%)",
          padding: "0px",
          borderRadius: "10px",
          backgroundColor: "transparent",
          filter: "brightness(95%)",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        },
      }}
    >
      <InventoryFilterForm
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
        setFilterColumns={props.setFilterColumns}
        setRenderedColumns={props.setRenderedColumns}
        renderedColumns={props.renderedColumns}
        columns={props.columns}
        filtering={props.filtering}
        setFiltering={props.setFiltering}
        filterModalDisplayAll={props.filterModalDisplayAll}
        setFilterModalDisplayAll={props.setFilterModalDisplayAll}
        filterModalFilters={props.filterModalFilters}
        setFilterModalFilters={props.setFilterModalFilters}
      />
    </Modal>
  );
};

export default InventoryModal;
