import Cookies from "js-cookie";
import logout from "./logout";

const api_url =
  process.env.REACT_APP_ENV === "production"
    ? `https://${process.env.REACT_APP_HOST}`
    : `http://localhost:4000`;

export default async function asyncAPICall(
  api_endpoint,
  method = "GET",
  body = {},
  response_callback_method = null,
  data_callback_method = null,
  catch_callback_method = null,
  require_auth_token = true,
  headers = null,
  doNotStringifyBody = false,
  doNotStringifyResponse = false
) {
  let userRole = Cookies.get("user_role");
  let auth_token = Cookies.get(`auth_token`) + `+${userRole}`;

  if (!require_auth_token) {
    auth_token = "not_required";
  } else {
    if (!auth_token || auth_token === "") {
      console.log("Auth Token Required");
      return false;
    }
    let expiration = Cookies.get("auth_expires");
    if (Date.parse(expiration) < Date.now()) {
      console.log("Expired Auth Token");
      return false;
    }
  }

  if (auth_token) {
    if (!headers) {
      headers = { "content-type": "application/json", auth_token: auth_token };
    } else {
      if (!("auth_token" in headers)) {
        headers["auth_token"] = auth_token;
      }
    }
    let payload = { method: method, headers: headers };
    if (method === "POST") {
      if (doNotStringifyBody) {
        payload.body = body;
      } else {
        payload.body = JSON.stringify(body);
      }
    }
    let response_function = (response) => {
      if (response.ok) {
        if (doNotStringifyResponse) {
          return response;
        }
        return response.json();
      } else if (response.status === 403 || response.status === 401) {
        logout();
      }
      let error = new Error(response.statusText);
      error.response = response;
      throw error;
    };
    if (response_callback_method) {
      response_function = response_callback_method;
    }
    let data_function = (data) => {};
    if (data_callback_method) {
      data_function = data_callback_method;
    }
    let catch_function = (error) => console.log(error);
    if (catch_callback_method) {
      catch_function = catch_callback_method;
    }

    await fetch(`${api_url}${api_endpoint}`, payload)
      .then((response) => response_function(response))
      .then((response) => data_function(response))
      .catch((response) => catch_function(response));

    return true;
  } else {
    return false;
  }
}

export function awaitAPICall(
  api_endpoint,
  method = "GET",
  body = {},
  response_callback_method = null,
  data_callback_method = null,
  catch_callback_method = null,
  require_auth_token = true
) {
  let userRole = Cookies.get("user_role");
  let auth_token = Cookies.get(`auth_token`) + `+${userRole}`;
  let expiration = Cookies.get("auth_expires");
  if (Date.parse(expiration) < Date.now()) {
    console.log("Expired Auth Token");
    return false;
  }

  if (!require_auth_token) {
    auth_token = "not_required";
  }
  if (auth_token) {
    let payload = {
      method: method,
      headers: { "content-type": "application/json", auth_token: auth_token },
    };
    if (method === "POST" || method === "PUT") {
      payload.body = JSON.stringify(body);
    }
    let response_function = async (response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403 || response.status === 401) {
        logout();
      }
      let error = new Error(response.statusText);
      error.response = response;
      throw error;
    };
    if (response_callback_method) {
      response_function = response_callback_method;
    }
    let data_function = async (data) => {};
    if (data_callback_method) {
      data_function = data_callback_method;
    }
    let catch_function = async (error) => console.log(error);
    if (catch_callback_method) {
      catch_function = catch_callback_method;
    }

    let fetchFromAPI = async () => {
      try {
        let response = await fetch(`${api_url}${api_endpoint}`, payload);
        let results = await response_function(response);
        await data_function(results);
      } catch (error) {
        await catch_function(error);
        return false;
      }
    };

    fetchFromAPI();

    return true;
  } else {
    return false;
  }
}
