import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { Link, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";

import { poColumns } from "../util/reportColumns";
import PoFilterModal from "../components/modals/PoFilterModal";
import PoSearchModal from "../components/modals/PoSearchModal";
import asyncAPICall from "../util/apiWrapper";
import LoadingBar from "../components/LoadingBar";
import { toCamelCase } from "../util/stringUtils";
import DownloadIcon from "../img/final-download-icon.svg";
import DestructionCertModal from "../components/modals/DestructionCertModal";
import CodPdfModal from "../components/modals/CodPdfModal";
import ManualCodGenerator from "../components/ManualCodGenerator";
import { successfulToast } from "../util/toastNotifications";

export default function PurchaseOrderManagement(props) {
  const [openModal, setOpenModal] = useState("");
  const [renderedReport, setRenderedReport] = useState("");
  const [pages, setPages] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectors, setSelectors] = useState([]);
  const [inventoryResults, setInventoryResults] = useState([]);
  const [totalInventoryRows, setTotalInventoryRows] = useState(0);
  const [loading, setLoading] = useState("loaded");
  const [searchQuery, setSearchQuery] = useState("");
  const [searching, setSearching] = useState("searched");
  const [searchExact, setSearchExact] = useState(false);
  const [filters, setFilters] = useState([]);
  const [chosenSelectors, setChosenSelectors] = useState([]);
  const [selectorStorage, setSelectorStorage] = useState([]);
  const [width, setWidth] = useState(0);
  const [filtering, setFiltering] = useState(false);
  const [filterModalDisplayAll, setFilterModalDisplayAll] = useState(false);
  const [filterModalFilters, setFilterModalFilters] = useState([]);
  const [csvFilename, setCsvFilename] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [codData, setCodData] = useState();
  const [codItemsReceived, setCodItemsReceived] = useState(0);
  const [publicId, setPublicId] = useState("");
  const [company, setCompany] = useState("Company");
  const [weight, setWeight] = useState(
    "Enter Weight in Pounds  (Numbers Only)"
  );
  const [equipmentDesc, setEquipmentDesc] = useState("Equipment Description");
  const [dateReceived, setDateReceived] = useState("Date Received");
  const [issueDate, setIssueDate] = useState("Issue Date");
  const [codId, setCodId] = useState("<Set When Generated>");
  const [dataByPtId, setDataByPtId] = useState();
  const [pdfVisibility, setPdfVisibility] = useState(false);
  const [showCloudModal, setShowCloudModal] = useState(false);
  const [codCheckMessage, setCodCheckMessage] = useState("");
  const [generateCod, setGenerateCod] = useState(false);

  const mounted = useRef(false);
  const prevValue = useRef("stale");
  const widthRef = useRef(0);
  const rowsRef = useRef(0);
  const reportRef = useRef("purchase");
  const retryRef = useRef(0);
  const modalLocation = useRef("po-mgmt");

  const {
    reportPage,
    setReportPage,
    searchPage,
    setSearchPage,
    renderedColumns,
    setRenderedColumns,
    purchaseOrderData,
    setPurchaseOrderData,
    justPurchaseOrderData,
    setJustPurchaseOrderData,
  } = props;

  window.onbeforeunload = () => {
    return "";
  };

  const handleDataPageChange = async (page) => {
    if (page === "s1") {
      setSearchPage(1);
    } else if (searching === "searching" || inventoryResults?.length > 0) {
      setSearchPage(page);
    } else if (renderedReport === "purchase") {
      setReportPage(page);
    }
  };

  // eslint-disable-next-line
  const fetchInventoryData = useCallback(_fetchInventoryData, [
    openModal,
    reportPage,
    props.cvCode,
    searchPage,
    searchQuery,
    inventoryResults?.length,
    chosenSelectors,
  ]);

  function _fetchInventoryData() {
    if (rowsRef.current === 100 && reportRef === "purchase") {
      return setTimeout(gatherData(), 5000);
    }
    if (openModal) {
      return;
    } else if (prevValue.current === true && openModal === "" && !searchQuery) {
      prevValue.current = false;
      return;
    }
    if (mounted.current) {
      setErrorMsg("pending");
    }

    const transferSelectors =
      chosenSelectors.length > 0 ? chosenSelectors.join(",") : "no_selectors";

    const body = searchQuery
      ? {
          report: "purchase",
          cvcode: props.cvCode === "TAMSLLC" ? "" : props.cvCode,
          selectors: transferSelectors,
          search_query: searchQuery,
          page: searchPage,
          per_page: 300,
          exact: searchExact ? "yes" : "no",
        }
      : {
          report: "purchase",
          cvcode: props.cvCode === "TAMSLLC" ? "" : props.cvCode,
          selectors: "no_selectors",
          search_query: "no_query",
          page: reportPage,
          per_page: 300,
        };

    if (
      props.cvCode.length > 0 &&
      props.cvCode !== "false" &&
      widthRef.current < 100
    ) {
      if (mounted.current) {
        setLoading("loading");
      }
      asyncAPICall(
        "/purchase-order-report/get",
        "POST",
        body,
        null,
        (data) => {
          if (data.report_data) {
            data.report_data.forEach((item) => {
              item.amountPaid = item.amountpaid;
              delete item.amountpaid;
              item.amountPaidBy = item.amountpaidby;
              delete item.amountpaidby;
              item.approvedBy = item.approvedby;
              delete item.approvedby;
              item.baseCurrency = item.basecurrency;
              delete item.basecurrency;
              item.billDistributionId = item.billdistributionid;
              delete item.billdistributionid;
              item.conditionCode = item.conditioncode;
              delete item.conditioncode;
              item.conditionId = item.conditionid;
              delete item.conditionid;
              item.contactContact = item.contactcontact;
              delete item.contactcontact;
              item.contactContactId = item.contactcontactid;
              delete item.contactcontactid;
              item.contactFax = item.contactfax;
              delete item.contactfax;
              item.contactPhone = item.contactphone;
              delete item.contactphone;
              item.contractByUs = item.contractbyus;
              delete item.contractbyus;
              item.contractComment = item.contractcomment;
              delete item.contractcomment;
              item.contractSentBy = item.contractsentby;
              delete item.contractsentby;
              item.conversionRate = item.conversionrate;
              delete item.conversionrate;
              item.countryCode = item.countrycode;
              delete item.countrycode;
              item.creditCardAppliedAmount = item.creditcardappliedamount;
              delete item.creditcardappliedamount;
              item.creditCardInfoId = item.creditcardinfoid;
              delete item.creditcardinfoid;
              item.currencyCode = item.currencycode;
              delete item.currencycode;
              item.cvFreightId = item.cvfreightid;
              delete item.cvfreightid;
              item.dateApproved = item.dateapproved;
              delete item.dateapproved;
              item.deliveryDate = item.deliverydate;
              delete item.deliverydate;
              item.downPayment = item.downpayment;
              delete item.downpayment;
              item.equipmentCost = item.equipmentcost;
              delete item.equipmentcost;
              item.equipmentPurchase = item.equipmentpurchase;
              delete item.equipmentpurchase;
              item.erasureMethodId = item.erasuremethodid;
              delete item.erasuremethodid;
              item.exRate = item.exrate;
              delete item.exrate;
              item.foreignAmount = item.foreignamount;
              delete item.foreignamount;
              item.foreignEquipmentCost = item.foreignequipmentcost;
              delete item.foreignequipmentcost;
              item.foreignFreight = item.foreignfreight;
              delete item.foreignfreight;
              item.foreignMiscCost = item.foreignmisccost;
              delete item.foreignmisccost;
              item.foreignStateTax = item.foreignstatetax;
              delete item.foreignstatetax;
              item.foreignTax1 = item.foreigntax1;
              delete item.foreigntax1;
              item.foreignTax2 = item.foreigntax2;
              delete item.foreigntax2;
              item.foreignTax3 = item.foreigntax3;
              delete item.foreigntax3;
              item.foreignTax4 = item.foreigntax4;
              delete item.foreigntax4;
              item.foreignTaxableSales = item.foreigntaxablesales;
              delete item.foreigntaxablesales;
              item.freightUsed = item.freightused;
              delete item.freightused;
              item.internalComment = item.internalcomment;
              delete item.internalcomment;
              item.isFreightTaxable = item.isfreighttaxable;
              delete item.isfreighttaxable;
              item.isMiscTaxable = item.ismisctaxable;
              delete item.ismisctaxable;
              item.isPaid = item.ispaid;
              delete item.ispaid;
              item.isTaxable = item.istaxable;
              delete item.istaxable;
              item.isTrade = item.istrade;
              delete item.istrade;
              item.itadPoType = item.itadpotype;
              delete item.itadpotype;
              item.itadTemplateId = item.itadtemplateid;
              delete item.itadtemplateid;
              item.lateCharge = item.latecharge;
              delete item.latecharge;
              item.locatedAtContact = item.locatedatcontact;
              delete item.locatedatcontact;
              item.locatedAtContactId = item.locatedatcontactid;
              delete item.locatedatcontactid;
              item.locatedAtFax = item.locatedatfax;
              delete item.locatedatfax;
              item.locatedAtPhone = item.locatedatphone;
              delete item.locatedatphone;
              item.miscChargeUsed = item.miscchargeused;
              delete item.miscchargeused;
              item.miscCost = item.misccost;
              delete item.misccost;
              item.nextApExt = item.nextapext;
              delete item.nextapext;
              item.nextLine = item.nextline;
              delete item.nextline;
              item.overrideComplete = item.overridecomplete;
              delete item.overridecomplete;
              item.pqId = item.pqid;
              delete item.pqid;
              item.ptId = item.ptid;
              delete item.ptid;
              item.purchaseDate = item.purchasedate;
              delete item.purchasedate;
              item.recAdded = item.recadded;
              delete item.recadded;
              item.receiveStatus = item.receivestatus;
              delete item.recievestatus;
              item.recType = item.rectype;
              delete item.rectype;
              item.serviceType = item.servicetype;
              delete item.servicetype;
              item.shipperName = item.shippername;
              delete item.shippername;
              item.shippingComment = item.shippingcomment;
              delete item.shippingcomment;
              item.shipToContact = item.shiptocontact;
              delete item.shiptocontact;
              item.shipToContactId = item.shiptocontactid;
              delete item.shiptocontactid;
              item.shipToFax = item.shiptofax;
              delete item.shiptofax;
              item.shipToPhone = item.shiptophone;
              delete item.shiptophone;
              item.shipVia = item.shipvia;
              delete item.shipvia;
              item.shipViaId = item.shipviaid;
              delete item.shipviaid;
              item.soNumber = item.sonumber;
              delete item.sonumber;
              item.stateTax = item.statetax;
              delete item.statetax;
              item.stateTaxPact = item.statetaxpact;
              delete item.statetaxpact;
              item.stId = item.stid;
              delete item.stid;
              item.tax1Auth = item.tax1auth;
              delete item.tax1auth;
              item.tax2Auth = item.tax2auth;
              delete item.tax2auth;
              item.tax3Auth = item.tax3auth;
              delete item.tax3auth;
              item.tax4Auth = item.tax4auth;
              delete item.tax4auth;
              item.tax1AuthId = item.tax1authid;
              delete item.tax1authid;
              item.tax2AuthId = item.tax2authid;
              delete item.tax2authid;
              item.tax3AuthId = item.tax3authid;
              delete item.tax3authid;
              item.tax4AuthId = item.tax4authid;
              delete item.tax4authid;
              item.taxableSales = item.taxablesales;
              delete item.taxablesales;
              item.taxState = item.taxstate;
              delete item.taxstate;
              item.termsId = item.termsid;
              delete item.termsid;
              item.totalOnly = item.totalonly;
              delete item.totalonly;
              item.totalsOnly = item.totalsonly;
              delete item.totalsonly;
              item.totalTax = item.totaltax;
              delete item.totaltax;
              item.trackingNumber = item.trackingnumber;
              delete item.trackingnumber;
              item.transAddressId = item.transaddressid;
              delete item.transaddressid;
              item.userDefined1 = item.userdefined1;
              delete item.userdefined1;
              item.userDefined2 = item.userdefined2;
              delete item.userdefined2;
              item.voidAfter = item.voidafter;
              delete item.voidafter;
              item.weArrangeTransp = item.wearrangetransp;
              delete item.wearrangetransp;
              item.wePayTransp = item.wepaytransp;
              delete item.wepaytransp;
              item.woId = item.woid;
              delete item.woid;
              item.cvCode = item.cvcode;
              delete item.cvcode;
              item.stateTaxPct = item.statetaxpct;
              delete item.statetaxpct;
              for (const row in item) {
                if (item[row] === "" || item[row] === undefined) {
                  delete item[row];
                  item[row] = "N/A";
                }
              }
            });

            if (data.message.includes("Failed")) {
              if (mounted.current) {
                setWidth((width) => width + 2);
                setTimeout(fetchInventoryData(), 5000);
              }
            } else {
              if (openModal === "search" || inventoryResults?.length > 0) {
                if (mounted.current) {
                  setWidth(100);
                  loadingDelay();
                  setInventoryResults(data.report_data);
                }
              } else {
                if (mounted.current) {
                  setWidth(100);
                  loadingDelay();
                  setErrorMsg(data.message);
                  setJustPurchaseOrderData(data.report_data);
                  setPurchaseOrderData(data.report_data);
                }
              }
            }
          }
        },
        (err) => {
          if (mounted.current) {
            setWidth((width) => width + 2);
            setTimeout(fetchInventoryData(), 5000);
            setErrorMsg("inv data");
          }
        },
        true
      );
    } else if (
      props.cvCode.length > 0 &&
      props.cvCode !== "false" &&
      widthRef.current >= 100
    ) {
      setTimeout(() => {
        if (widthRef.current >= 100) {
          retryRef.current += 1;
          if (retryRef.current <= 5) {
            setWidth(0);
            gatherData();
          } else {
            retryRef.current = 0;
          }
        }
      }, 10000);
    }
  }

  // eslint-disable-next-line
  const gatherData = useCallback(_gatherData, [
    openModal,
    renderedReport,
    reportPage,
    searchPage,
    fetchInventoryData,
    props.cvCode,
    searchQuery,
    chosenSelectors,
  ]);

  function _gatherData() {
    if (openModal) {
      return;
    } else if (prevValue.current === true && openModal === "" && !searchQuery) {
      prevValue.current = false;
      return;
    }
    if (mounted.current) {
      setLoading("loading");
      setErrorMsg("pending");
    }
    if (renderedReport === "purchase" && widthRef.current < 100) {
      const transferSelectors =
        chosenSelectors.length > 0 ? chosenSelectors.join(",") : "no_selectors";

      const body = searchQuery
        ? {
            report: "purchase",
            cvcode: props.cvCode === "TAMSLLC" ? "" : props.cvCode,
            selectors: transferSelectors,
            search_query: searchQuery,
            page: searchPage,
            per_page: 300,
            exact: searchExact ? "yes" : "no",
          }
        : {
            report: "purchase",
            cvcode: props.cvCode === "TAMSLLC" ? "" : props.cvCode,
            selectors: "no_selectors",
            search_query: "no_query",
            page: reportPage,
            per_page: 300,
          };

      asyncAPICall(
        "/report-count/get",
        "POST",
        body,
        null,
        (count_data) => {
          if (mounted.current) {
            setTotalInventoryRows((tIr) =>
              count_data.data_total !== 100
                ? Number(count_data.data_total)
                : tIr
            );
            setWidth((w) => {
              if (w < 66 && count_data.data_total !== 100) {
                return 66;
              } else {
                return w + 5;
              }
            });
            return count_data.data_total !== 100
              ? fetchInventoryData()
              : setTimeout(gatherData(), 5000);
          }
        },
        (err) => {
          if (mounted.current) {
            setTimeout(setErrorMsg("Failed"), 5000);
          }
        },
        true
      );
    } else if (widthRef.current >= 100) {
      setTimeout(() => {
        if (widthRef.current >= 100) {
          retryRef.current += 1;
          if (retryRef.current <= 5) {
            setWidth(0);
            gatherData();
          } else {
            retryRef.current = 0;
          }
        }
      }, 10000);
    }
  }

  const delay = useCallback(
    (ms) =>
      setTimeout(() => {
        gatherData();
      }, ms),
    [gatherData]
  );

  const loadingDelay = (ms) =>
    setTimeout(() => {
      setLoading("loaded");
      setWidth(0);
    }, 2000);

  const parseRenderedColumns = useCallback(() => {
    return renderedColumns.map((column) => {
      let new_string = toCamelCase(column.name);
      return new_string;
    });
  }, [renderedColumns]);

  const createFilteredDataObjects = useCallback(
    (comparisonArray) => {
      return justPurchaseOrderData.map((item) => {
        const obj = {};

        Object.keys(item).forEach(() => {
          comparisonArray.forEach((colTitle) => {
            obj[colTitle] = item[colTitle];
          });
        });

        return obj;
      });
    },
    [justPurchaseOrderData]
  );

  const newData = useMemo(
    () => createFilteredDataObjects(parseRenderedColumns()),
    [createFilteredDataObjects, parseRenderedColumns]
  );

  const paginationComponentOptions = {
    noRowsPerPage: true,
  };

  const handlePageChange = (value) => {
    if (pageNum + value < 0 || pageNum + value > pages.length - 1) {
      return;
    } else {
      setPageNum((pN) => pN + value);
    }
  };

  const handleColumnChange = useCallback(() => {
    if (renderedReport === "purchase") {
      setRenderedColumns((rC) => {
        if (filtering) {
          return rC;
        } else {
          return poColumns;
        }
      });
    }
    // eslint-disable-next-line
  }, [renderedReport, filtering]);

  const handleClick = () => {
    setSearching("searched");
    setFilterModalFilters([]);
    setSearchQuery("");
    setFilters([]);
    setChosenSelectors([]);
    setSelectorStorage([]);
    setFiltering(false);
    setInventoryResults([]);
  };

  const handleShowManualCodModal = () => {
    setCompany("Company");
    setWeight("Enter Weight in Pounds  (Numbers Only)");
    setEquipmentDesc("Equipment Description");
    setDateReceived("Date Received");
    setIssueDate("Issue Date");
    setCodId("<Set When Generated>");

    return setShowModal(true);
  };

  useEffect(() => {
    if (codCheckMessage && showModal) {
      if (codCheckMessage === "cod: yes") {
        setShowCloudModal((cM) => {
          setShowModal(cM);

          return !cM;
        });
      } else if (codCheckMessage === "cod: no") {
        setShowModal((sM) => {
          setShowCloudModal(sM);

          return !sM;
        });
      }
    }
  }, [codCheckMessage, showModal, showCloudModal]);

  useEffect(() => {
    if (codCheckMessage && !showModal && !showCloudModal) {
      if (codCheckMessage === "cod: yes") {
        setShowCloudModal(true);
        setCodCheckMessage("");
      } else {
        setCodCheckMessage("");
      }
    } else if (codCheckMessage && showModal && !showCloudModal) {
      if (codCheckMessage === "cod: yes") {
        setShowCloudModal(true);
        setCodCheckMessage("");
      } else {
        setCodCheckMessage("");
      }
    } else if (codCheckMessage && !showModal && showCloudModal) {
      if (codCheckMessage === "cod: no") {
        setShowCloudModal(false);
        setCodCheckMessage("");
      } else {
        setCodCheckMessage("");
      }
    } else if (codCheckMessage && showModal && showCloudModal) {
      if (codCheckMessage === "cod: yes") {
        setCodCheckMessage("");
      } else {
        setShowCloudModal(false);
        setCodCheckMessage("");
      }
    }
  }, [codCheckMessage, showModal, showCloudModal]);

  useEffect(() => {
    if (showModal && codData) {
      setCodData(false);
    }
    if (codData && !showModal) {
      const codCheck = async () => {
        const response = await asyncAPICall(
          `/cod-check/${modalLocation.current}`,
          "POST",
          { cod_id: codData.ptId },
          null,
          (data) => {
            setCodCheckMessage(data.message);
            setCodItemsReceived(data.items_received);
            setPublicId(data.public_id);
          },
          (err) => {
            console.log("COD Check Error: ", err);
          },
          true,
          null,
          false
        );

        return response;
      };

      codCheck();
    }
  }, [codData, showModal]);

  useEffect(() => {
    if (pdfVisibility) {
      setShowModal(false);
    }
  }, [pdfVisibility]);

  useEffect(() => {
    if (dataByPtId) {
      setPdfVisibility(true);
    } else {
      setPdfVisibility(false);
    }
  }, [dataByPtId]);

  useEffect(() => {
    setRenderedReport("purchase");
  }, []);

  useEffect(() => {
    handleColumnChange();
  }, [handleColumnChange]);

  useEffect(() => {
    setPageNum(0);
  }, [renderedColumns]);

  useEffect(() => {
    reportRef.current = renderedReport;
  }, [renderedReport]);

  useEffect(() => {
    rowsRef.current = totalInventoryRows;
  }, [totalInventoryRows]);

  useEffect(() => {
    widthRef.current = width;
  }, [width]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (errorMsg.includes("Failed")) {
      setWidth((w) => w + 5);
      delay(5000);
    }
  }, [errorMsg, delay]);

  useEffect(() => {
    setSelectors(parseRenderedColumns());
  }, [renderedColumns, parseRenderedColumns]);

  useEffect(() => {
    widthRef.current = 0;
  }, [searchPage, reportPage, renderedReport, openModal]);

  useEffect(() => {
    setRenderedColumns(poColumns);
    setReportPage((iP) => iP);
    // eslint-disable-next-line
  }, [renderedReport]);

  useEffect(() => {
    const splitColumns = [];
    const length = Math.ceil(renderedColumns.length / 8);

    for (let i = 0; i < length; i++) {
      const min = i * 8;
      const max = (i + 1) * 8;

      if (length === i + 1 && renderedReport === "purchase") {
        splitColumns.push(renderedColumns.slice(min, poColumns.length));
      } else {
        splitColumns.push(renderedColumns.slice(min, max));
      }
    }
    setPages(splitColumns);
  }, [renderedColumns, renderedReport]);

  useEffect(() => {
    if (pageNum + 1 === pages.length || renderedColumns.length < 8) {
      setRightArrow(false);
    } else {
      setRightArrow(true);
    }
    if (pageNum === 0) {
      setLeftArrow(false);
    } else {
      setLeftArrow(true);
    }
  }, [pageNum, renderedColumns, pages]);

  useEffect(() => {
    if (purchaseOrderData?.length) {
      if (renderedReport === "purchase") {
        setPurchaseOrderData(justPurchaseOrderData);
        setRenderedColumns((rC) => {
          if (filtering) {
            return rC;
          } else {
            return poColumns;
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [renderedReport, purchaseOrderData?.length, justPurchaseOrderData]);

  useEffect(() => {
    if (openModal !== "filter" && openModal !== "search") {
      if (searchQuery) {
        if (renderedReport === "purchase") {
          setRenderedColumns((rC) => {
            if (filtering) {
              return rC;
            } else {
              return poColumns;
            }
          });
        }
      } else if (filterModalFilters?.length > 0 || filterModalDisplayAll) {
        if (renderedReport === "purchase") {
          setRenderedColumns((rC) => {
            if (filtering) {
              return rC;
            } else {
              return poColumns;
            }
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [
    openModal,
    renderedReport,
    filterModalDisplayAll,
    filterModalFilters,
    searchQuery,
    filtering,
  ]);

  useEffect(() => {
    if (!searchQuery && openModal === "search") {
      setInventoryResults([]);
    }
  }, [openModal, searchQuery, inventoryResults?.length]);

  useEffect(() => {
    setTimeout(gatherData(), 5000);
  }, [renderedReport, gatherData, searchQuery]);

  useEffect(() => {
    setLoading("loading");
  }, [reportPage, renderedReport]);

  useEffect(() => {
    if (loading === "loading") {
      setWidth((w) => {
        return w < 33 ? 33 : w;
      });
    }
  }, [loading]);

  useEffect(() => {
    if (openModal) {
      prevValue.current = true;
    }
  }, [openModal]);

  useEffect(() => {
    setSearching("searched");
    setFilterModalFilters([]);
    setSearchQuery("");
    setFilters([]);
    setChosenSelectors([]);
    setSelectorStorage([]);
    setFiltering(false);
    setInventoryResults([]);
  }, [renderedReport]);

  useEffect(() => {
    if (searching === "searching") {
      let resultTo = 300 * searchPage;
      const resultFrom = resultTo - 299;

      if (resultTo > totalInventoryRows) {
        resultTo = totalInventoryRows;
      }
      setCsvFilename(`PO Results ${resultFrom} - ${resultTo}`);
    } else {
      let resultTo = 300 * reportPage;
      const resultFrom = resultTo - 299;

      if (resultTo > totalInventoryRows) {
        resultTo = totalInventoryRows;
      }
      setCsvFilename(`PO Results ${resultFrom} - ${resultTo}`);
    }
  }, [searching, searchPage, reportPage, totalInventoryRows]);

  return (
    <div className="purchase-order-management-wrapper">
      <h1 className="title">Purchase Orders</h1>
      <CSVLink
        className="csv-download"
        data={
          renderedReport === "purchase" && inventoryResults?.length > 0
            ? inventoryResults
            : renderedReport === "purchase" && !filtering
            ? justPurchaseOrderData
            : renderedReport === "purchase"
            ? newData
            : []
        }
        filename={csvFilename}
        onClick={() => {
          if (searching === "searching") {
            let resultTo = 300 * searchPage;
            const resultFrom = resultTo - 299;

            if (resultTo > totalInventoryRows) {
              resultTo = totalInventoryRows;
            }
            successfulToast(`Downloaded Results ${resultFrom} - ${resultTo}`);
          } else {
            let resultTo = 300 * reportPage;
            const resultFrom = resultTo - 299;

            if (resultTo > totalInventoryRows) {
              resultTo = totalInventoryRows;
            }
            successfulToast(`Downloaded Results ${resultFrom} - ${resultTo}`);
          }
          return; // necessary for CSVLink Component
        }}
      >
        <img src={DownloadIcon} alt="download icon" />
      </CSVLink>
      <div className="link-label">
        <h6>CSV</h6>
      </div>

      <div
        className={
          renderedReport === "purchase-order" &&
          justPurchaseOrderData.length < 1
            ? "report-loading-bar-visible"
            : "report-loading-bar-hidden"
        }
      >
        <LoadingBar loading={loading} width={width} setWidth={setWidth} />
      </div>

      <LoadingBar loading={loading} width={width} setWidth={setWidth} />

      <div className="header-wrapper">
        <div className="left-side">
          <button className="filter-btn" onClick={() => setOpenModal("filter")}>
            Filter
          </button>
          <button
            className="search-btn"
            onClick={
              searching === "searching"
                ? handleClick
                : () => setOpenModal("search")
            }
          >
            {searching === "searching" && "Reset "}
            Search
          </button>
          <button
            className="manual-cod-button"
            onClick={() => handleShowManualCodModal()}
          >
            Manual COD
          </button>
        </div>
        <i
          className={`fas fa-angle-left fa-2x left-arrow ${
            leftArrow ? "active-arrow" : ""
          }`}
          onClick={() => handlePageChange(-1)}
        ></i>
        <i
          className={`fas fa-angle-right fa-2x right-arrow ${
            rightArrow ? "active-arrow" : ""
          }`}
          onClick={() => handlePageChange(1)}
        ></i>
        <div className="right-side">
          <NavLink
            to="/purchase-order-management"
            className="purchase-orders-nav-link"
          >
            <button className="purchase-orders-button">Purchase Orders</button>
          </NavLink>

          <Link to="/documents-page" className="side-nav-links">
            <button className="documents-button">Documents</button>
          </Link>
        </div>
      </div>
      <DataTable
        className="table"
        columns={pages[pageNum]}
        data={
          renderedReport === "purchase" && inventoryResults?.length > 0
            ? inventoryResults
            : renderedReport === "purchase"
            ? justPurchaseOrderData
            : []
        }
        noHeader
        pagination
        paginationServer
        paginationDefaultPage={
          searching === "searching" ? searchPage : reportPage
        }
        paginationTotalRows={totalInventoryRows}
        paginationPerPage={300}
        onChangePage={handleDataPageChange}
        paginationComponentOptions={paginationComponentOptions}
      />

      {openModal === "filter" && (
        <PoFilterModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          filtering={filtering}
          setFiltering={setFiltering}
          filterModalDisplayAll={filterModalDisplayAll}
          setFilterModalDisplayAll={setFilterModalDisplayAll}
          filterModalFilters={filterModalFilters}
          setFilterModalFilters={setFilterModalFilters}
          setRenderedColumns={setRenderedColumns}
          renderedColumns={renderedColumns}
          columns={poColumns}
        />
      )}

      {openModal === "search" && (
        <PoSearchModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          setRenderedColumns={setRenderedColumns}
          renderedColumns={renderedColumns}
          columns={poColumns}
          purchaseOrderData={justPurchaseOrderData}
          selectors={selectors}
          setChosenSelectors={setChosenSelectors}
          selectorStorage={selectorStorage}
          setSelectorStorage={setSelectorStorage}
          chosenSelectors={chosenSelectors}
          setSearchQuery={setSearchQuery}
          searchExact={searchExact}
          setSearchExact={setSearchExact}
          setFilters={setFilters}
          filters={filters}
          searching={searching}
          setSearching={setSearching}
          handleDataPageChange={handleDataPageChange}
          setRowResults={setInventoryResults}
          renderedReport={renderedReport}
        />
      )}
      {showModal && (
        <DestructionCertModal
          showModal={showModal}
          setShowModal={setShowModal}
          company={company}
          setCompany={setCompany}
          weight={weight}
          setWeight={setWeight}
          equipmentDesc={equipmentDesc}
          setEquipmentDesc={setEquipmentDesc}
          dateReceived={dateReceived}
          setDateReceived={setDateReceived}
          issueDate={issueDate}
          setIssueDate={setIssueDate}
          codId={codId}
          setCodId={setCodId}
          setGenerateCod={setGenerateCod}
        />
      )}
      {showCloudModal && <CodPdfModal />}

      <div className="cod-creator-wrapper">
        <ManualCodGenerator
          company={company}
          weight={weight}
          equipmentDesc={equipmentDesc}
          dateReceived={dateReceived}
          issueDate={issueDate}
          codId={codId}
          pdfVisibility={pdfVisibility}
          setPdfVisibility={setPdfVisibility}
          dataByPtId={dataByPtId}
          setDataByPtId={setDataByPtId}
          generateCod={generateCod}
          setGenerateCod={setGenerateCod}
        />
      </div>

      {showCloudModal && (
        <CodPdfModal
          modalLocation={modalLocation.current}
          codMessage={codCheckMessage}
          showModal={showCloudModal}
          setShowModal={setShowCloudModal}
          pdfCodData={codData}
          setPdfCodData={setCodData}
          codItemsReceived={codItemsReceived}
          publicId={publicId}
        />
      )}
    </div>
  );
}
