import { useState, useEffect, useCallback, useRef } from "react";

import JSZip from "jszip";
import asyncAPICall from "../util/apiWrapper";

function ZipDownloader(props) {
  const [publicIdArray, setPublicIdArray] = useState([]);

  const linkRef = useRef();

  const { codIdsArray, handleClearRows } = props;

  async function createFile(myUrl) {
    let response = await fetch(myUrl);
    let data = await response.blob();

    return data;
  }

  function downloadBlob(blob, name = "file.pdf") {
    const blobUrl = URL.createObjectURL(blob);

    let link = linkRef.current;

    link.href = blobUrl;
    link.download = name;

    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
  }

  const handleClick = useCallback(async () => {
    await asyncAPICall(
      "/get-cod-public-ids",
      "POST",
      codIdsArray,
      null,
      (data) => setPublicIdArray(data),
      (err) => console.warn("Couldn't get public IDs"),
      true,
      null,
      false
    );
  }, [codIdsArray]);

  useEffect(() => {
    if (publicIdArray?.length > 0) {
      const zip = new JSZip();

      const pdfs = zip.folder("pdfs");
      const sortedPublicIdArray = publicIdArray.slice().sort();
      const sortedCodIdsArray = codIdsArray.slice().sort();
      sortedPublicIdArray.forEach((codId, idx) => {
        const myFile = createFile(
          `https://res.cloudinary.com/duwet11zt/image/upload/${codId}.pdf`
        );
        pdfs.file(`${sortedCodIdsArray[idx]}.pdf`, myFile, { base64: true });
      });

      zip.generateAsync({ type: "blob" }).then((content) => {
        downloadBlob(content, "codZip.zip");
        handleClearRows();
      });
      setPublicIdArray([]);
    }
  }, [codIdsArray, publicIdArray, publicIdArray?.length, handleClearRows]);

  return (
    <div className="download-cod-button-wrapper">
      <a ref={linkRef} style={{ display: "none" }}></a>
      <button className="zip-downloader" onClick={handleClick}>
        Download {codIdsArray ? codIdsArray?.length : "0"} COD
        {codIdsArray?.length === 1 ? "" : "s"}
      </button>
    </div>
  );
}

export default ZipDownloader;
