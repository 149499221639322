import { useEffect, useState, useCallback, useRef } from "react";
import DataTable from "react-data-table-component";
import uuid4 from "uuid4";

import { inventoryColumns, reconColumns } from "../util/reportColumns";
import InventoryFilterModal from "../components/modals/InventoryFilterModal";
import SearchFilterModal from "../components/modals/SearchFilterModal";
import asyncAPICall from "../util/apiWrapper";
import LoadingBar from "../components/LoadingBar";
import { toCamelCase } from "../util/stringUtils";
import ZipDownloader from "../components/ZipDownloader";
import CsvPolling from "../components/modals/CsvPollingModal";
import { errorToastNotification } from "../util/toastNotifications";

export default function InventoryList(props) {
  const [openModal, setOpenModal] = useState(false);
  const [renderedReport, setRenderedReport] = useState("inventory");
  const [pages, setPages] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectors, setSelectors] = useState([]);
  const [reconResults, setReconResults] = useState([]);
  const [inventoryResults, setInventoryResults] = useState([]);
  const [totalInventoryRows, setTotalInventoryRows] = useState(0);
  const [totalReconRows, setTotalReconRows] = useState(0);
  const [loading, setLoading] = useState("loaded");
  const [searchQuery, setSearchQuery] = useState("");
  const [searching, setSearching] = useState("searched");
  const [filters, setFilters] = useState([]);
  const [chosenSelectors, setChosenSelectors] = useState([]);
  const [selectorStorage, setSelectorStorage] = useState([]);
  const [width, setWidth] = useState(0);
  const [filtering, setFiltering] = useState(false);
  const [filterModalDisplayAll, setFilterModalDisplayAll] = useState(false);
  const [filterModalFilters, setFilterModalFilters] = useState([]);
  const [checkedRecords, setCheckedRecords] = useState([]);
  const [arrayWithoutDuplicates, setArrayWithoutDuplicates] = useState([]);
  const [arrayWithDuplicates, setArrayWithDuplicates] = useState([]);
  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [openPolling, setOpenPolling] = useState(false);
  const [currentCsvId, setCurrentCsvId] = useState("");
  const [csvBlob, setCsvBlob] = useState(false);
  const [csvCancelled, setCsvCancelled] = useState(false);
  const [searchExact, setSearchExact] = useState(false);

  const mounted = useRef(false);
  const prevValue = useRef("stale");
  const widthRef = useRef(0);
  const rowsRef = useRef(0);
  const reportRef = useRef("inventory");
  const retryRef = useRef(0);

  const {
    inventoryPage,
    setInventoryPage,
    reconPage,
    setReconPage,
    searchPage,
    setSearchPage,
    renderedColumns,
    setRenderedColumns,
    inventoryData,
    setInventoryData,
    justInventoryData,
    setJustInventoryData,
    justReconData,
    setJustReconData,
  } = props;

  window.onbeforeunload = () => {
    return "";
  };

  const handleDataPageChange = (page) => {
    handleClearRows();
    if (page === "s1") {
      setSearchPage(1);
    } else if (
      searching === "searching" ||
      inventoryResults?.length > 0 ||
      reconResults?.length > 0
    ) {
      setSearchPage(page);
    } else if (renderedReport === "recon") {
      setReconPage(page);
    } else if (renderedReport === "inventory") {
      setInventoryPage(page);
    }
  };

  // eslint-disable-next-line
  const fetchInventoryData = useCallback(_fetchInventoryData, [
    openModal,
    inventoryPage,
    props.cvCode,
    searchPage,
    searchQuery,
    inventoryResults?.length,
    chosenSelectors,
  ]);

  // eslint-disable-next-line
  const fetchReconData = useCallback(_fetchReconData, [
    openModal,
    props.cvCode,
    reconPage,
    searchPage,
    searchQuery,
    reconResults?.length,
    chosenSelectors,
  ]);

  function _fetchInventoryData() {
    if (rowsRef.current === 100 && reportRef === "inventory") {
      return setTimeout(gatherData(), 5000);
    }
    if (openModal) {
      return;
    } else if (
      prevValue.current === true &&
      openModal === false &&
      !searchQuery
    ) {
      return;
    }
    if (mounted.current) {
      setErrorMsg("pending");
    }

    const transferSelectors =
      chosenSelectors.length > 0 ? chosenSelectors.join(",") : "no_selectors";

    const body = searchQuery
      ? {
          report: "inventory",
          cvcode: props.cvCode === "TAMSLLC" ? "" : props.cvCode,
          selectors: transferSelectors,
          search_query: searchQuery,
          page: searchPage,
          per_page: 300,
          exact: searchExact ? "yes" : "no",
        }
      : {
          report: "inventory",
          cvcode: props.cvCode === "TAMSLLC" ? "" : props.cvCode,
          selectors: "no_selectors",
          search_query: "no_query",
          page: inventoryPage,
          per_page: 300,
        };

    if (
      props.cvCode.length > 0 &&
      props.cvCode !== "false" &&
      widthRef.current < 100
    ) {
      if (mounted.current) {
        setLoading("loading");
      }
      asyncAPICall(
        "/inventory-report/get",
        "POST",
        body,
        null,
        (data) => {
          if (data.report_data) {
            if (data.report_data[0]?.message) {
              errorToastNotification(
                "ERROR: Record(s) could not be found. Displayed Records will Reset Momentarily."
              );
              setSearching("searched");
              setFilterModalFilters([]);
              setSearchQuery("");
              setFilters([]);
              setChosenSelectors([]);
              setSelectorStorage([]);
              setFiltering(false);

              if (renderedReport === "recon") {
                setReconResults([]);
              } else {
                setInventoryResults([]);
              }

              return;
            }
            data.report_data.forEach((item) => {
              item.dateReceived = item.datereceived;
              delete item.datereceived;
              item.poId = item.ptid;
              delete item.ptid;
              item.poLine = item.ptline;
              delete item.ptline;
              item.cvCode = item.cvcode;
              delete item.cvcode;
              item.serialNumber = item.serialnumber;
              delete item.serialnumber;
              item.assetId = item.userdefined1;
              delete item.userdefined1;
              item.model = item.description;
              delete item.description;
              item.itemNumber = item.itemnumber;
              delete item.itemnumber;
              item.referenceNumber = item.referencenumber;
              delete item.referencenumber;
              delete item.rep;
              delete item.status;
              for (const row in item) {
                if (item[row] === "" || item[row] === undefined) {
                  delete item[row];
                  item[row] = "N/A";
                }
              }
            });

            if (data.message.includes("Failed")) {
              if (mounted.current) {
                setWidth((width) => width + 2);
                setTimeout(fetchInventoryData(), 5000);
              }
            } else {
              if (openModal === "search" || inventoryResults?.length > 0) {
                if (mounted.current) {
                  setWidth(100);
                  loadingDelay();
                  setInventoryResults(data.report_data);
                }
              } else {
                if (mounted.current) {
                  setWidth(100);
                  loadingDelay();
                  setErrorMsg(data.message);
                  setJustInventoryData(data.report_data);
                  setInventoryData(data.report_data);
                }
              }
            }
          }
        },
        (err) => {
          if (mounted.current) {
            setWidth((width) => width + 2);
            setTimeout(fetchInventoryData(), 5000);
            setErrorMsg("inv data");
          }
        },
        true
      );
    } else if (
      props.cvCode.length > 0 &&
      props.cvCode !== "false" &&
      widthRef.current >= 100
    ) {
      setTimeout(() => {
        if (widthRef.current >= 100) {
          retryRef.current += 1;
          if (retryRef.current <= 5) {
            setWidth(0);
            gatherData();
          } else {
            retryRef.current = 0;
          }
        }
      }, 10000);
    }
  }

  function _fetchReconData() {
    if (rowsRef.current === 100 && reportRef === "recon") {
      return setTimeout(gatherData(), 5000);
    }
    if (openModal) {
      return;
    } else if (
      prevValue.current === true &&
      openModal === false &&
      !searchQuery
    ) {
      return;
    }
    if (mounted.current) {
      setErrorMsg("pending");
    }

    const transferSelectors =
      chosenSelectors.length > 0 ? chosenSelectors.join(",") : "no_selectors";

    const body = searchQuery
      ? {
          report: "recon",
          cvcode: props.cvCode === "TAMSLLC" ? "" : props.cvCode,
          selectors: transferSelectors,
          search_query: searchQuery,
          page: searchPage,
          per_page: 300,
          exact: searchExact ? "yes" : "no",
        }
      : {
          report: "recon",
          cvcode: props.cvCode === "TAMSLLC" ? "" : props.cvCode,
          selectors: "no_selectors",
          search_query: "no_query",
          page: reconPage,
          per_page: 300,
        };

    if (
      props.cvCode.length > 0 &&
      props.cvCode !== "false" &&
      widthRef.current < 100
    ) {
      if (mounted.current) {
        setLoading("loading");
      }
      asyncAPICall(
        "/recon-report/get",
        "POST",
        body,
        null,
        (data) => {
          if (data.report_data[0]?.message) {
            errorToastNotification(
              "ERROR: Record(s) could not be found. Displayed Records will Reset Momentarily."
            );
            setSearching("searched");
            setFilterModalFilters([]);
            setSearchQuery("");
            setFilters([]);
            setChosenSelectors([]);
            setSelectorStorage([]);
            setFiltering(false);

            if (renderedReport === "recon") {
              setReconResults([]);
            } else {
              setInventoryResults([]);
            }
            return;
          }
          data.report_data.forEach((item) => {
            item.serialNumber = item.serialnumber;
            delete item.serialnumber;
            item.itemNumber = item.itemnumber;
            delete item.itemnumber;
            item.dateReceived = item.datereceived;
            delete item.datereceived;
            item.invoicedDate = item.bookeddate;
            delete item.bookeddate;
            item.customerTag = item.userdefined1;
            delete item.userdefined1;
            item.unitPrice = item.unitprice;
            delete item.unitprice;
            item.unitCost = item.unitcost;
            delete item.unitcost;
            item.costBuild = item.userdefined2;
            delete item.userdefined2;
            item.poId = item.ptid;
            delete item.ptid;
            item.poLine = item.ptline;
            delete item.ptline;
            delete item.referencenumber;
            delete item.originalunitcost;
            delete item.inventoryid;
            delete item.selectedinventoryid;
            delete item.itemid;
            delete item.categoryid;
            for (const row in item) {
              if (item[row] === "" || item[row] === undefined) {
                delete item[row];
                item[row] = "N/A";
              }
            }
          });
          if (data.message.includes("Failed")) {
            if (mounted.current) {
              setWidth((width) => width + 2);
              setTimeout(fetchReconData(), 5000);
            }
          } else {
            if (openModal === "search" || reconResults?.length > 0) {
              if (mounted.current) {
                setWidth(100);
                loadingDelay();
                setReconResults(data.report_data);
              }
            } else {
              if (mounted.current) {
                setWidth(100);
                loadingDelay();
                setErrorMsg(data.message);
                setJustReconData(data.report_data);
                setInventoryData(data.report_data);
              }
            }
          }
        },
        (err) => {
          if (mounted.current) {
            setWidth((width) => width + 5);
            setTimeout(fetchReconData(), 5000);
            setErrorMsg("Failed");
          }
        },
        true
      );
    } else if (
      props.cvCode.length > 0 &&
      props.cvCode !== "false" &&
      widthRef.current >= 100
    ) {
      setTimeout(() => {
        if (widthRef.current >= 100) {
          retryRef.current += 1;
          if (retryRef.current <= 5) {
            setWidth(0);
            gatherData();
          } else {
            retryRef.current = 0;
          }
        }
      }, 10000);
    }
  }

  // eslint-disable-next-line
  const gatherData = useCallback(_gatherData, [
    openModal,
    renderedReport,
    inventoryPage,
    reconPage,
    searchPage,
    fetchInventoryData,
    fetchReconData,
    props.cvCode,
    searchQuery,
    chosenSelectors,
  ]);

  function _gatherData() {
    if (openModal) {
      return;
    } else if (
      prevValue.current === true &&
      openModal === false &&
      !searchQuery
    ) {
      return;
    }
    if (mounted.current) {
      setLoading("loading");
      setErrorMsg("pending");
    }
    if (renderedReport === "recon" && widthRef.current < 100) {
      const transferSelectors =
        chosenSelectors.length > 0 ? chosenSelectors.join(",") : "no_selectors";

      const body = searchQuery
        ? {
            report: "recon",
            cvcode: props.cvCode === "TAMSLLC" ? "" : props.cvCode,
            selectors: transferSelectors,
            search_query: searchQuery,
            page: searchPage,
            per_page: 300,
            exact: searchExact ? "yes" : "no",
          }
        : {
            report: "recon",
            cvcode: props.cvCode === "TAMSLLC" ? "" : props.cvCode,
            selectors: "no_selectors",
            search_query: "no_query",
            page: reconPage,
            per_page: 300,
          };

      asyncAPICall(
        "/report-count/get",
        "POST",
        body,
        null,
        (count_data) => {
          if (mounted.current) {
            setTotalReconRows((tRr) =>
              count_data.data_total !== 100
                ? Number(count_data.data_total)
                : tRr
            );
            setWidth((w) => {
              if (w < 66 && count_data.data_total !== 100) {
                return 66;
              } else {
                return w + 5;
              }
            });
            return count_data.data_total !== 100
              ? fetchReconData()
              : setTimeout(gatherData(), 5000);
          }
        },
        (err) => {
          if (mounted.current) {
            setTimeout(setErrorMsg("Failed"), 5000);
          }
        },
        true
      );
    } else if (renderedReport === "inventory" && widthRef.current < 100) {
      const transferSelectors =
        chosenSelectors.length > 0 ? chosenSelectors.join(",") : "no_selectors";

      const body = searchQuery
        ? {
            report: "inventory",
            cvcode: props.cvCode === "TAMSLLC" ? "" : props.cvCode,
            selectors: transferSelectors,
            search_query: searchQuery,
            page: searchPage,
            per_page: 300,
            exact: searchExact ? "yes" : "no",
          }
        : {
            report: "inventory",
            cvcode: props.cvCode === "TAMSLLC" ? "" : props.cvCode,
            selectors: "no_selectors",
            search_query: "no_query",
            page: inventoryPage,
            per_page: 300,
          };

      asyncAPICall(
        "/report-count/get",
        "POST",
        body,
        null,
        (count_data) => {
          if (mounted.current) {
            setTotalInventoryRows((tIr) =>
              count_data.data_total !== 100
                ? Number(count_data.data_total)
                : tIr
            );
            setWidth((w) => {
              if (w < 66 && count_data.data_total !== 100) {
                return 66;
              } else {
                return w + 5;
              }
            });
            return count_data.data_total !== 100
              ? fetchInventoryData()
              : setTimeout(gatherData(), 5000);
          }
        },
        (err) => {
          if (mounted.current) {
            setTimeout(setErrorMsg("Failed"), 5000);
          }
        },
        true
      );
    } else if (widthRef.current >= 100) {
      setTimeout(() => {
        if (widthRef.current >= 100) {
          retryRef.current += 1;
          if (retryRef.current <= 5) {
            setWidth(0);
            gatherData();
          } else {
            retryRef.current = 0;
          }
        }
      }, 10000);
    }
  }

  const delay = useCallback(
    (ms) =>
      setTimeout(() => {
        gatherData();
      }, ms),
    [gatherData]
  );

  const loadingDelay = (ms) =>
    setTimeout(() => {
      setLoading("loaded");
      setWidth(0);
    }, 2000);

  const parseRenderedColumns = useCallback(() => {
    return renderedColumns.map((column) => {
      let new_string = toCamelCase(column.name);
      return new_string;
    });
  }, [renderedColumns]);

  const paginationComponentOptions = {
    noRowsPerPage: true,
  };

  const handlePageChange = (value) => {
    if (pageNum + value < 0 || pageNum + value > pages.length - 1) {
      return;
    } else {
      setPageNum((pN) => pN + value);
    }
  };

  const handleColumnChange = (e) => {
    e.preventDefault();
    setLoading("loading");

    if (e.target.id === "inventory-button") {
      setRenderedReport("inventory");
    } else if (e.target.id === "recon-button") {
      setRenderedReport("recon");
    }
    if (renderedReport === "inventory") {
      setRenderedColumns((rC) => {
        if (filtering) {
          return rC;
        } else {
          return inventoryColumns;
        }
      });
    } else if (renderedReport === "recon") {
      setRenderedColumns((rC) => {
        if (filtering) {
          return rC;
        } else {
          return reconColumns;
        }
      });
    }
  };

  const handleClick = () => {
    setSearching("searched");
    setFilterModalFilters([]);
    setSearchQuery("");
    setFilters([]);
    setChosenSelectors([]);
    setSelectorStorage([]);
    setFiltering(false);

    if (renderedReport === "recon") {
      setReconResults([]);
    } else {
      setInventoryResults([]);
    }
  };

  function downloadBlob(blob, name = "file.pdf") {
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");

    link.href = blobUrl;
    link.download = name;

    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    document.body.removeChild(link);
  }

  const handleCsvClick = () => {
    const id = uuid4();
    const stringId = String(id);
    setCurrentCsvId(stringId);

    asyncAPICall(
      "/csv/get",
      "POST",
      {
        filterModalFilters,
        chosenSelectors,
        renderedReport,
        totalReconRows,
        totalInventoryRows,
        searchQuery,
        id,
      },
      null,
      (data) => {
        console.log("CSV Get Data: ", data);
      },
      (err) => console.warn("csv endpoint error: ", err),
      true
    );

    setOpenPolling(true);
  };

  const handleSelectionChange = (rowData) => {
    const unfilteredArray = rowData.selectedRows.map((record) => {
      return record.ptid_pt;
    });

    const filteredArray = [...new Set(unfilteredArray)];
    setArrayWithDuplicates(unfilteredArray);
    setArrayWithoutDuplicates(filteredArray);
  };

  const rowDisabledCriteria = (row) => row.has_cod === 0;

  const handleClearRows = useCallback(() => {
    setToggledClearRows((cR) => !cR);
  }, []);

  useEffect(() => {
    if (csvBlob && !openPolling) {
      downloadBlob(csvBlob, "tams-report.csv");
      setCsvBlob(false);
    }
  }, [csvBlob, openPolling]);

  useEffect(() => {
    if (csvCancelled) {
      asyncAPICall(
        "/csv/poll",
        "POST",
        {
          message: "cancel",
          currentCsvId,
        },
        null,
        (data) => console.log("cancel data: ", data),
        (err) => console.warn("cancel csv error: ", err),
        true
      );
      setCsvCancelled(false);
    }
  }, [csvCancelled, currentCsvId]);

  useEffect(() => {
    handleClearRows();
  }, [renderedReport, handleClearRows]);

  useEffect(() => {
    return () => {
      handleClearRows();
    };
  }, [handleClearRows]);

  useEffect(() => {
    reportRef.current = renderedReport;
  }, [renderedReport]);

  useEffect(() => {
    rowsRef.current = totalReconRows;
  }, [totalReconRows]);

  useEffect(() => {
    rowsRef.current = totalInventoryRows;
  }, [totalInventoryRows]);

  useEffect(() => {
    widthRef.current = width;
  }, [width]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (errorMsg.includes("Failed")) {
      setWidth((w) => w + 5);
      delay(5000);
    }
  }, [errorMsg, delay]);

  useEffect(() => {
    setSelectors(parseRenderedColumns());
  }, [renderedColumns, parseRenderedColumns]);

  useEffect(() => {
    widthRef.current = 0;
  }, [searchPage, inventoryPage, reconPage, renderedReport, openModal]);

  useEffect(() => {
    if (renderedReport === "recon") {
      setRenderedColumns(reconColumns);
      setReconPage((rP) => rP);
    } else {
      setRenderedColumns(inventoryColumns);
      setInventoryPage((iP) => iP);
    }
    // eslint-disable-next-line
  }, [renderedReport]);

  useEffect(() => {
    const splitColumns = [];
    const length = Math.ceil(renderedColumns.length / 9);

    for (let i = 0; i < length; i++) {
      const min = i * 9;
      const max = (i + 1) * 9;

      if (length === i + 1 && renderedReport === "inventory") {
        splitColumns.push(renderedColumns.slice(min, inventoryColumns.length));
      } else if (length === i + 1 && renderedReport === "recon") {
        splitColumns.push(renderedColumns.slice(min, reconColumns.length));
      } else {
        splitColumns.push(renderedColumns.slice(min, max));
      }
    }
    setPages(splitColumns);
  }, [renderedColumns, renderedReport]);

  useEffect(() => {
    if (pageNum + 1 === pages.length || renderedColumns.length < 9) {
      setRightArrow(false);
    } else {
      setRightArrow(true);
    }
    if (pageNum === 0) {
      setLeftArrow(false);
    } else {
      setLeftArrow(true);
    }
  }, [pageNum, renderedColumns, pages]);

  useEffect(() => {
    if (inventoryData?.length) {
      if (renderedReport === "inventory") {
        setInventoryData(justInventoryData);
        setRenderedColumns((rC) => {
          if (filtering) {
            return rC;
          } else {
            return inventoryColumns;
          }
        });
      } else if (renderedReport === "recon") {
        setInventoryData(justReconData);
        setRenderedColumns((rC) => {
          if (filtering) {
            return rC;
          } else {
            return reconColumns;
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [renderedReport, inventoryData?.length, justInventoryData, justReconData]);

  useEffect(() => {
    if (openModal !== "filter" && openModal !== "search") {
      if (searchQuery) {
        if (renderedReport === "inventory") {
          setRenderedColumns((rC) => {
            if (filtering) {
              return rC;
            } else {
              return inventoryColumns;
            }
          });
        } else if (renderedReport === "recon") {
          setRenderedColumns((rC) => {
            if (filtering) {
              return rC;
            } else {
              return reconColumns;
            }
          });
        }
      } else if (filterModalFilters?.length > 0 || filterModalDisplayAll) {
        if (renderedReport === "inventory") {
          setRenderedColumns((rC) => {
            if (filtering) {
              return rC;
            } else {
              return inventoryColumns;
            }
          });
        } else if (renderedReport === "recon") {
          setRenderedColumns((rC) => {
            if (filtering) {
              return rC;
            } else {
              return reconColumns;
            }
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [
    openModal,
    renderedReport,
    filterModalDisplayAll,
    filterModalFilters,
    searchQuery,
    filtering,
  ]);

  useEffect(() => {
    if (!searchQuery && openModal === "search") {
      setInventoryResults([]);
      setReconResults([]);
    }
  }, [openModal, searchQuery, inventoryResults?.length, reconResults?.length]);

  useEffect(() => {
    setTimeout(gatherData(), 5000);
  }, [renderedReport, gatherData, searchQuery]);

  useEffect(() => {
    setLoading("loading");
  }, [inventoryPage, reconPage, renderedReport]);

  useEffect(() => {
    if (loading === "loading") {
      setWidth((w) => {
        return w < 33 ? 33 : w;
      });
    }
  }, [loading]);

  useEffect(() => {
    if (openModal) {
      prevValue.current = true;
    } else {
      prevValue.current = false;
    }
  });

  useEffect(() => {
    setSearching("searched");
    setFilterModalFilters([]);
    setSearchQuery("");
    setFilters([]);
    setChosenSelectors([]);
    setSelectorStorage([]);
    setFiltering(false);

    if (renderedReport === "recon") {
      setReconResults([]);
    } else {
      setInventoryResults([]);
    }
  }, [renderedReport]);

  useEffect(() => {
    if (mounted.current) {
      const currentData =
        renderedReport === "inventory" && inventoryResults?.length > 0
          ? inventoryResults
          : renderedReport === "recon" && reconResults?.length > 0
          ? reconResults
          : renderedReport === "inventory"
          ? justInventoryData
          : renderedReport === "recon"
          ? justReconData
          : [];

      if (currentData?.length > 0) {
        asyncAPICall(
          "/get-inv-cod-links",
          "POST",
          currentData,
          null,
          (data) => setCheckedRecords(data),
          (err) => console.warn("links_error: ", err),
          true,
          null,
          false
        );
      }
    }
  }, [
    renderedReport,
    inventoryResults?.length,
    inventoryResults,
    reconResults?.length,
    reconResults,
    justInventoryData,
    justReconData,
  ]);

  return (
    <div
      className="inventory-management-wrapper"
      style={
        loading === "loading" && widthRef.current !== 100
          ? { pointerEvents: "none" }
          : { pointerEvents: "all" }
      }
    >
      <h1 className="title">Inventory Management</h1>
      <div
        className={
          renderedReport === "inventory" && justInventoryData.length < 1
            ? "page-loading-bar-visible"
            : renderedReport === "recon" && justReconData.length < 1
            ? "report-loading-bar-visible"
            : "report-loading-bar-hidden"
        }
      >
        <LoadingBar loading={loading} width={width} setWidth={setWidth} />
      </div>

      <ZipDownloader
        codIdsArray={arrayWithoutDuplicates}
        handleClearRows={handleClearRows}
      />
      <div className="uncheck-rows-button-wrapper">
        <button className="uncheck-selections-btn" onClick={handleClearRows}>
          Uncheck {arrayWithDuplicates ? arrayWithDuplicates?.length : "0"} row
          {arrayWithDuplicates?.length === 1 ? "" : "s"}
        </button>
      </div>

      <LoadingBar loading={loading} width={width} setWidth={setWidth} />

      <div className="header-wrapper">
        <div className="left-side">
          <button className="filter-btn" onClick={() => setOpenModal("filter")}>
            Filter
          </button>
          <button
            className="search-btn"
            onClick={
              searching === "searching"
                ? handleClick
                : () => setOpenModal("search")
            }
          >
            {searching === "searching" && "Reset "}
            Search
          </button>
        </div>

        <i
          className={`fas fa-angle-left fa-2x left-arrow ${
            leftArrow ? "active-arrow" : ""
          }`}
          onClick={() => handlePageChange(-1)}
        />
        <i
          className={`fas fa-angle-right fa-2x right-arrow ${
            rightArrow ? "active-arrow" : ""
          }`}
          onClick={() => handlePageChange(1)}
        />

        <div className="right-side">
          <button
            disabled={loading === "loading" ? true : false}
            className={`${renderedReport === "inventory" ? "active" : ""}`}
            id="inventory-button"
            onClick={handleColumnChange}
          >
            Inventory Report
          </button>
          <button
            disabled={loading === "loading" ? true : false}
            className={`${renderedReport === "recon" ? "active" : "inactive"}`}
            id="recon-button"
            onClick={handleColumnChange}
          >
            Disposition Report
          </button>

          <button className="csv-download" onClick={() => handleCsvClick()}>
            Download CSV
          </button>
        </div>
      </div>

      <DataTable
        className="table"
        columns={pages[pageNum]}
        data={checkedRecords}
        noHeader
        pagination
        paginationServer
        paginationDefaultPage={
          searching === "searching"
            ? searchPage
            : renderedReport === "recon"
            ? reconPage
            : inventoryPage
        }
        paginationTotalRows={
          renderedReport === "recon" ? totalReconRows : totalInventoryRows
        }
        paginationPerPage={300}
        onChangePage={handleDataPageChange}
        paginationComponentOptions={paginationComponentOptions}
        selectableRows
        onSelectedRowsChange={handleSelectionChange}
        selectableRowDisabled={rowDisabledCriteria}
        selectableRowsNoSelectAll
        selectableRowsHighlight
        clearSelectedRows={toggledClearRows}
      />

      {openModal === "filter" && (
        <InventoryFilterModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          filtering={filtering}
          setFiltering={setFiltering}
          filterModalDisplayAll={filterModalDisplayAll}
          setFilterModalDisplayAll={setFilterModalDisplayAll}
          filterModalFilters={filterModalFilters}
          setFilterModalFilters={setFilterModalFilters}
          setRenderedColumns={setRenderedColumns}
          renderedColumns={renderedColumns}
          columns={
            renderedReport === "inventory" ? inventoryColumns : reconColumns
          }
        />
      )}

      {openModal === "search" && (
        <SearchFilterModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          setRenderedColumns={setRenderedColumns}
          renderedColumns={renderedColumns}
          columns={
            renderedReport === "inventory" ? inventoryColumns : reconColumns
          }
          inventoryData={
            renderedReport === "inventory" ? justInventoryData : justReconData
          }
          selectors={selectors}
          setChosenSelectors={setChosenSelectors}
          selectorStorage={selectorStorage}
          setSelectorStorage={setSelectorStorage}
          chosenSelectors={chosenSelectors}
          setSearchQuery={setSearchQuery}
          setSearchExact={setSearchExact}
          searchExact={searchExact}
          setFilters={setFilters}
          filters={filters}
          searching={searching}
          setSearching={setSearching}
          handleDataPageChange={handleDataPageChange}
          setRowResults={
            renderedReport === "inventory"
              ? setInventoryResults
              : renderedReport === "recon"
              ? setReconResults
              : []
          }
          renderedReport={renderedReport}
        />
      )}
      {openPolling && (
        <CsvPolling
          openModal={openPolling}
          setOpenModal={setOpenPolling}
          currentCsvId={currentCsvId}
          setCsvCancelled={setCsvCancelled}
          setCsvBlob={setCsvBlob}
          csvBlob={csvBlob}
        />
      )}
    </div>
  );
}
