import { useState } from "react";
import { Link } from "react-router-dom";

import asyncAPICall from "../util/apiWrapper";
import Logo from "../img/logo.svg";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const routeChange = (route = "/login") => {
    props.history.push(route);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    asyncAPICall(
      "/user/pw_change_request",
      "POST",
      {
        email: email,
      },
      null,
      (res) => routeChange("/login/email/sent"),
      (err) =>
        setErrorMessage(
          "Your account has been deactivated. Please contact your account executive."
        ),
      false
    );
  };

  return (
    <div className="login-container">
      <img src={Logo} alt="tams header company logo" />

      <div className="forgot-password-wrapper">
        <h2>RESET Password</h2>

        <div className="forgot-password-header">
          Enter your email address and instructions will be sent to proceed.
        </div>

        <input
          required
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: "80px" }}
        />

        <div
          className="error-message"
          style={{
            position: "absolute",
            top: "58%",
            left: "49.5%",
            transform: "translate(-50%)",
            textAlign: "center",
          }}
        >
          {errorMessage}
        </div>

        <button
          className="confirm-button send-recovery"
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </button>

        <Link className="back-to-login" to="/login">
          Back to Login
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
