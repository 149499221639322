import { useEffect, useCallback } from "react";

import asyncAPICall from "../util/apiWrapper";

export default function ManualCodGenerator(props) {
  const {
    company,
    weight,
    equipmentDesc,
    dateReceived,
    issueDate,
    codId,
    generateCod,
    setGenerateCod,
  } = props;

  const sendData = useCallback(
    async (codId) => {
      const body = {
        company: company?.cvname,
        po_id: codId,
        weight: weight,
        equipment_desc: equipmentDesc,
        date_received: dateReceived,
        date_issued: issueDate,
        cvcode: company?.cvcode,
      };

      const callResponse = await asyncAPICall(
        "/cod-add",
        "POST",
        body,
        null,
        (data) => setGenerateCod(false),
        (err) => setGenerateCod(false),
        true,
        null,
        false
      );

      return callResponse;
    },
    // eslint-disable-next-line
    [company, weight, equipmentDesc, dateReceived, issueDate]
  );

  useEffect(() => {
    if (generateCod && codId === "<Set When Generated>") {
      asyncAPICall(
        "/generate-cod-id",
        "GET",
        null,
        null,
        (data) => sendData(data.cod_id),
        (err) => console.error("Manual COD: ID Get: ", err),
        false,
        null
      );
    }
  }, [generateCod, codId, sendData]);

  return <></>;
}
