import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import Logo from "../img/logo.svg";
import asyncAPICall from "../util/apiWrapper";

const TwoFactor = (props) => {
  const { userId, loginData } = props;
  const [errorMsg, setErrorMsg] = useState("");
  const [twoFactorToken, setTwoFactorToken] = useState("");
  const history = useHistory("");

  useEffect(() => {
    if (userId === "") {
      history.push("/login");
    }
  }, [userId, history]);

  const checkAuthToken = (e) => {
    e.preventDefault();
    asyncAPICall(
      "/check-two-factor",
      "POST",
      { user_id: userId, two_auth_token: twoFactorToken },
      (response) => {
        if (response.ok) {
          let auth_token = loginData.auth_token;
          let user_role = loginData.user.role;
          let user_name = loginData.user.first_name;
          let org_id_cookie = loginData.user.org_id;

          Cookies.set("auth_token", auth_token);
          Cookies.set("user_role", user_role);
          Cookies.set("user_name", user_name);
          Cookies.set("org_id", org_id_cookie);
          Cookies.set("auth_expires", loginData.expiration);
          history.push("/overview");
        } else {
          setErrorMsg("Invalid/Expired token");
        }
      },
      null,
      (err) => {
        console.log(err);
      },
      false
    );
  };

  return (
    <div className="login-container">
      <img src={Logo} alt="tams header company logo" />

      <div className="login-wrapper">
        <form className="form-wrapper" onSubmit={(e) => checkAuthToken(e)}>
          <h1>Two-Factor Authentication</h1>
          <input
            id="twoFactorAuth"
            name="twoFactorAuth"
            type="twoFactorAuth"
            placeholder="Input Token From Email"
            className="login-input"
            onChange={(e) => setTwoFactorToken(e.target.value)}
          />

          <div className={`error-message ${!errorMsg ? "hidden" : null}`}>
            {errorMsg ? errorMsg : "No Error"}
          </div>

          <div />

          <div className="button-wrapper">
            <button className="confirm-button login-button" type="submit">
              Verify
            </button>
            <div />
          </div>
        </form>
        <div className="contact-info">
          Email was sent from clientportal@tamsolutions.com
        </div>
      </div>
    </div>
  );
};

export default TwoFactor;
