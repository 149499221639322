import { useState, useEffect, useRef, useCallback } from "react";
import Modal from "react-modal";

import CsvLoadingBar from "../CsvLoadingBar";
import asyncAPICall from "../../util/apiWrapper";

Modal.setAppElement("#root");

const CsvPolling = (props) => {
  const [csvProgress, setCsvProgress] = useState("");
  const [csvLoading, setCsvLoading] = useState(false);

  const mountedRef = useRef(false);

  const { currentCsvId, setCsvCancelled, setOpenModal, setCsvBlob, csvBlob } =
    props;

  const handleClose = useCallback(() => {
    if (csvProgress !== "100.00%") {
      setCsvCancelled(true);
      setOpenModal(false);
    }
    //eslint-disable-next-line
  }, [csvProgress]);

  const calculateProgress = (data) => {
    let splitData = data.split("-");
    let dividend = Number(splitData[0]);
    let modifiedDividend = dividend * 10000;
    let divisor = Number(splitData[1]);
    let answer = Math.floor(modifiedDividend / divisor);
    let stringAnswer = String(answer);
    let stringAnswerLengthOfWholeNumber = stringAnswer?.length - 2;
    let formattedAnswer;
    if (stringAnswerLengthOfWholeNumber === -1) {
      formattedAnswer = `0.0${stringAnswer.slice(-1)}`;
    } else if (stringAnswerLengthOfWholeNumber === 0) {
      formattedAnswer = `0.${stringAnswer.slice(-2)}`;
    } else {
      formattedAnswer = `${stringAnswer.slice(
        0,
        stringAnswerLengthOfWholeNumber
      )}.${stringAnswer.slice(-2)}`;
    }
    return setCsvProgress(`${formattedAnswer}%`);
  };

  useEffect(() => {
    mountedRef.current = true;
    setCsvLoading(true);

    return () => {
      mountedRef.current = false;
      setCsvLoading(false);
    };
  }, []);

  useEffect(() => {
    let delay;
    if (csvProgress === "100.00%") {
      delay = setTimeout(() => {
        setOpenModal(false);
      }, 5000);
    }

    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [csvProgress]);

  useEffect(() => {
    if (csvBlob) {
      handleClose();
    }
  }, [csvBlob, handleClose]);

  useEffect(() => {
    let interval;
    if (mountedRef.current) {
      interval = setInterval(() => {
        if (currentCsvId && csvProgress !== "100.00%") {
          asyncAPICall(
            "/csv/poll",
            "POST",
            {
              currentCsvId,
            },
            null,
            (data) => {
              if (mountedRef.current) {
                const getJsonData = (myJsonData) => myJsonData.json();

                getJsonData(data).then((res) => {
                  if (res?.progress) {
                    calculateProgress(res.progress);
                  }
                });
              }
            },
            (err) => {
              if (mountedRef.current) {
                console.warn("CSV Warning: ", err);
              }
            },
            true,
            null,
            false,
            true
          );
        }
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [currentCsvId, csvProgress]);

  useEffect(() => {
    if (csvProgress === "100.00%") {
      asyncAPICall(
        "/csv/poll",
        "POST",
        {
          currentCsvId,
          finished: "finished",
        },
        null,
        (data) => {
          if (mountedRef.current) {
            const getBlobData = (myBlobData) => myBlobData.blob();

            getBlobData(data).then((res) => {
              setCsvBlob(res);
            });
          }
        },
        (err) => {
          if (mountedRef.current) {
            console.warn("CSV Warning: ", err);
          }
        },
        true,
        null,
        false,
        true
      );
    }
    // eslint-disable-next-line
  }, [currentCsvId, csvProgress]);

  return (
    <Modal
      isOpen={props.openModal}
      onRequestClose={() => handleClose()}
      style={{
        overlay: {
          backdropFilter: "blur(2px)",
          backgroundColor: "rgb(49, 53, 62, 0.5)",
          zIndex: "2",
        },

        content: {
          border: "1px solid black",
          position: "absolute",
          width: "fit-content",
          height: "fit-content",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -70%)",
          padding: "0px",
          borderRadius: "10px",
          backgroundColor: "transparent",
          filter: "brightness(95%)",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        },
      }}
    >
      <div className="csv-polling-modal-container">
        <div className="header-wrapper">CSV Downloader</div>
        <div className="subheader-wrapper">
          Exiting downloader will cancel operation
        </div>

        <div className="loading-bar-wrapper">
          <CsvLoadingBar csvLoading={csvLoading} width={csvProgress} />
        </div>
      </div>
    </Modal>
  );
};

export default CsvPolling;
