export default function LoadingBar(props) {
  const { width } = props;

  const styles = {
    maxWidth: width + "%",
    transition: `${width > 66 ? 0.25 : width === 0 ? 0 : 2}s all ease-in-out`,
    visibility: `${props.loading === "loading" ? "visible" : "hidden"}`,
  };

  return (
    <div
      className={
        props.loading === "loading"
          ? "loading-bar-visible"
          : "loading-bar-hidden"
      }
    >
      <div style={styles}></div>
    </div>
  );
}
