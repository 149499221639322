import { useState, useEffect } from "react";

import Cookies from "js-cookie";
import AccessManagementModal from "../components/modals/AccessManagementModal";

const AccessManagementPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState({});
  const userRole = Cookies.get("user_role");

  const { orgId, userData, setUserData, loggedInUser } = props;

  const handleNewUser = (userInformation) => {
    setUserData((u) => [...u, userInformation]);
  };

  const handleUpdateUser = (userInformation) => {
    setUserData((u) => {
      const index = u.findIndex(
        (user) => user.user_id === userInformation.user_id
      );

      u[index] = userInformation;

      return u;
    });
  };

  const handleEditUser = (user, editType) => {
    if (editType === "edit") {
      setUserToEdit(user);
    }

    setShowModal((m) => !m);
  };

  const handleShowModal = () => {
    setShowModal((m) => !m);
    setUserToEdit({});
  };

  const renderUsers = () => {
    let users = [];
    if (userRole === "admin") {
      users = userData.filter((user) => user.role !== "super-admin");
    } else {
      users = userData;
    }

    return users.map((user) => {
      const checkOrCancel = user.active ? (
        <i className="fas fa-check-circle" style={{ color: "#478E41" }}></i>
      ) : (
        <i className="fas fa-ban" style={{ color: "#C0430D" }}></i>
      );
      const color = user.active ? "#478E41" : "#C0430D";

      return (
        <div className="data-row" key={user.user_id}>
          <div className="name">
            {user.first_name} {user.last_name}
          </div>

          <div className="company-column">{user.organization.name}</div>

          <div className="email">{user.email}</div>

          <div className="user-role">
            {user.role === "admin"
              ? "client-admin"
              : user.role === "user"
              ? "client-user"
              : user.role}
          </div>

          {loggedInUser?.user_id && loggedInUser?.user_id === user.user_id ? (
            <div
              className="actions"
              onClick={() => handleEditUser(user, "edit")}
            >
              <i className="fas fa-cog"></i>Edit User
            </div>
          ) : loggedInUser?.user_id &&
            loggedInUser?.role === "super-admin" &&
            user.role === "admin" ? (
            <div
              className="actions"
              onClick={() => handleEditUser(user, "edit")}
            >
              <i className="fas fa-cog"></i>Edit User
            </div>
          ) : loggedInUser?.user_id &&
            loggedInUser?.role === "admin" &&
            user.role === "user" ? (
            <div
              className="actions"
              onClick={() => handleEditUser(user, "edit")}
            >
              <i className="fas fa-cog"></i>Edit User
            </div>
          ) : (
            <div className="disabled-actions">
              <i className="fas fa-cog"></i>Edit User
            </div>
          )}

          <div style={{ color: color }} className="status">
            {checkOrCancel}
            {user.active ? "Active" : "Deactivated"}
          </div>
          <hr />
        </div>
      );
    });
  };

  useEffect(() => {
    if (!showModal) {
      setUserToEdit({});
    }
  }, [showModal]);

  return (
    <div>
      <div
        className={`access-management-container ${
          showModal ? "blur-background" : ""
        }`}
      >
        <AccessManagementModal
          setShowModal={handleShowModal}
          showModal={showModal}
          userToEdit={userToEdit}
          orgId={orgId}
          handleNewUser={handleNewUser}
          handleUpdateUser={handleUpdateUser}
          cvCode={props.cvCode}
          myCompanyName={props.myCompanyName}
          loggedInUser={loggedInUser}
        />

        <div className="header-container">
          <h1>Access Management</h1>

          <div className="button-holder">
            <button className="add-user" onClick={() => handleEditUser("add")}>
              Add User
            </button>
          </div>
        </div>

        <div className="header-table-container">
          <div className="header-table">
            <h4 className="user">User Name</h4>
            <h4 className="company">Company</h4>
            <h4 className="email">Email</h4>
            <h4 className="role">User Role</h4>
            <h4 className="action">Actions</h4>
            <h4 className="status">Status</h4>
          </div>
        </div>

        <div className="data-container">{renderUsers()}</div>
      </div>
    </div>
  );
};

export default AccessManagementPage;
